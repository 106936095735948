import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import CreatableSelect from 'react-select/creatable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  useCreateTransactionMutation,
  useGetMaterialSubTypeQuery,
  useUpdateTransactionMutation,
} from '../../../app/api/services/createTransaction.service';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import LocationPicker from '../../../components/locationPicker';
import { apiUrl } from '../../../app/api/services/apiUrl';
import { helper } from '../../../app/api/api.helper';
import ConfirmationPopup from '../../../components/confirmationPopup';
import {
  ColorType,
  commanLabel,
  fileType,
  measurementTypes,
  routesConstant,
  transactionRegistryFileUploadSections,
} from '../../../constants/comman.constant';
import {
  isValidFile,
  onBehalfRole,
} from '../../../shared/remake-green-utils/common';
import { commanValidation } from '../../../constants/comman.validation.constant';
import {
  additionalDocumentInfo,
  basicDetails,
  buyerAndSellerDetails,
  debitNoteForBuyer,
  errorJson,
  ewayOrLorryReceipt,
  goodRecivedNote,
  goodTransferDetails,
  outputMaterialDetails,
  loadingWeightSlipDetails,
  NexStageTransactionInfo,
  otherData,
  PreviousStageTransactionInfo,
  recyclingCertificateInfo,
  unloadingweightSlipDetails,
} from './transactionRegisterProps';
import { transactionRequestValidator } from './transactionRegistryValidator';
import { isEmptyDeep } from '../../../utils/helper/common';
import { useTranslation } from 'react-i18next';
import { disableScrollForTypeNumber } from '../../../utils/utilFunctions';
import { RootState } from '../../../app/types/store';
import { store } from '../../../app/store';
import { userType } from '../../../constants/user.constant';
const TransactionRegistry = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [basic, setBasic] = useState(basicDetails);
  const [buyerSellerDetails, setBuyerSellerDetails] = useState(
    buyerAndSellerDetails
  );
  const [loadingSlipDetails, setLoadingSlipDetails] = useState(
    loadingWeightSlipDetails
  );
  const [unLoadingSlipDetails, setUnLoadingSlipDetails] = useState(
    unloadingweightSlipDetails
  );
  const [buyerLocation, setBuyerLocation] = useState({ lat: 0, lng: 0 });
  const [isBuyerLocation, setIsBuyerLocation] = useState(false);
  const [sellerLocationText, setSellerLocationText] = useState('');
  const [buyerLocationText, setBuyerLocationText] = useState('');
  const [sellerLocation, setSellerLocation] = useState({ lat: 0, lng: 0 });
  const [isSellerLocation, setIsSellerLocation] = useState(false);
  const [ewayLorryReceipt, setEwayLorryReceipt] = useState(ewayOrLorryReceipt);
  const [debitNote, setDebitNotet] = useState(debitNoteForBuyer);
  const [goodNote, setGoodNote] = useState(goodRecivedNote);
  const [goodDetails, setGoodDetails] = useState(goodTransferDetails);
  const [outputDetails, setOutputDetails] = useState(outputMaterialDetails);
  const [additionalDocuments, setAdditionalDocuments] = useState([
    additionalDocumentInfo,
  ]);
  const [recyclingCertificate, setRecyclingCertificate] = useState(
    recyclingCertificateInfo
  );
  const [otherDetails, setOtherDetails] = useState(otherData);
  const [nextDetails, setNextDetails] = useState(NexStageTransactionInfo);
  const [previousDetails, setPreviousDetails] = useState(
    PreviousStageTransactionInfo
  );
  const [selectMaterialName, setSelectMaterialName] = useState(null);
  const [materialNameInfo, setMaterialNameInfo] = useState({});
  const [materialName, setMaterialName] = useState('');
  const [selectOnBehalfName, setSelectOnBehalfName] = useState(null);
  const [onBehalfNameInfo, setOnBehalfNameInfo] = useState({});
  const [onBehalfName, setOnBehalfName] = useState('');
  const [selectBuyerName, setSelectBuyerName] = useState(null);
  const [buyerNameInfo, setBuyerNameInfo] = useState({});
  const [buyerName, setBuyerName] = useState('');
  const [selectAssociatedProjectName, setSelectAssociatedProjectName] =
    useState(null);
  const [associatedProjectNameInfo, setAssociatedProjectNameInfo] = useState(
    {}
  );
  const [associatedProjectName, setAssociatedProjectName] = useState('');
  const [selectSellerName, setSelectSellerName] = useState(null);
  const [sellerNameInfo, setSellerNameInfo] = useState({});
  const [sellerName, setSellerName] = useState('');
  const [createTransaction] = useCreateTransactionMutation();
  const [validated, setValidated] = useState(false);
  const { data: materialSubData, refetch: getMaterialSubType } =
    useGetMaterialSubTypeQuery(basic.material_type);
  const [valueChainData, setvalueChainData] = useState<any[]>([]);
  const [associatedProjectData, setAssociatedPrjectData] = useState<any[]>([]);
  const [categoryData, setCategoryData] = useState([]);
  const [utilityData, setUtillityData] = useState([]);
  const [materialTypeData, setMaterialTypeData] = useState([]);
  const [materialSubTypeData, setMaterialSubTypeData] = useState<any[]>([]);
  const [isInwardTransfer, setIsInwardTransfer] = useState(false);
  const [updateTransaction] = useUpdateTransactionMutation();
  const [discardPopup, setDiscardPopup] = useState(false);
  const [submitPopup, setSubmitPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmited] = useState(false); // Form Submitted or not
  const [errors, setErrors] = useState<any>(errorJson);
  const state: RootState = store.getState();
  useEffect(() => {
    handleSellerBuyerLatlng();
    getValueChainPartnerData();
    getAssociatedProjectData();
    getSettingData();
  }, [materialSubData, buyerLocation, sellerLocation]);

  const getSettingData = async () => {
    let path = apiUrl.getAllSetting;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let allSetting = res?.data && res?.data?.jsonData;
      setCategoryData(allSetting?.Category);
      setUtillityData(allSetting?.Utility);
      setMaterialTypeData(allSetting?.Material_type);
      materialSubTypeOption(materialSubData?.data?.allSubCategory);
    } else {
      toast.error(res.message);
    }
  };

  const getValueChainPartnerData = async () => {
    let path = apiUrl.getValueChainPartners;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let valueChainPartnerData = res?.data;
      valueChainPartnerOption(valueChainPartnerData);
      if (state.auth?.user?.value_chain_id && userType.VALUE_CHAIN_PARTNER == state.auth?.user?.userTypeId) {
        const object: any = valueChainPartnerData.find(
          (certiRow: any) =>
            Number(certiRow?.id) === Number(state.auth?.user?.value_chain_id)
        );

        let onBehalOption: any = {
          value: object?.id,
          label: object?.name,
        };
        handleOnBehalfName(onBehalOption);
      }
    } else {
      toast.error(res.message);
    }
  };

  const getAssociatedProjectData = async () => {
    let path = apiUrl.getAssociatedProjects;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      let associatedProjectData = res?.data;
      associatedProjectOptions(associatedProjectData);
    } else {
      toast.error(res.message);
    }
  };

  const materialSubTypeOption = (materialSubType: any) => {
    var temArray: any[] = [];
    materialSubType &&
      materialSubType.map((item: any) => {
        temArray.push({ value: item.id, label: item.name });
      });
    setMaterialSubTypeData(temArray || []);
  };

  const valueChainPartnerOption = (value: any) => {
    var temArray: any[] = [];
    value &&
      value.map((item: any) => {
        temArray.push({ value: item.id, label: item.name });
      });
    setvalueChainData(temArray || []);
  };

  const associatedProjectOptions = (value: any) => {
    var temArray: any[] = [];
    value &&
      value.map((item: any) => {
        temArray.push({ value: item.id, label: item.name });
      });
    setAssociatedPrjectData(temArray || []);
  };

  const handleBasicChange = async (e: any) => {
    setBasic({
      ...basic,
      [e.target.name]: e.target.value,
    });
    if (isSubmitted) {
      validateForm({
        ...{ ...basic, [e.target.name]: e.target.value },
        loadingSlipDetails,
        ...buyerSellerDetails,
        additionalDocuments,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        debitNote,
        goodNote,
        otherDetails,
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
    if (e.target.name === 'material_type') {
      await setBasic({
        ...basic,
        [e.target.name]: e.target.value,
      });
      await getMaterialSubType();
      setMaterialNameInfo({});
      setSelectMaterialName(null);
      setMaterialName('');
      if (isSubmitted) {
        validateForm({
          ...{ ...basic, [e.target.name]: e.target.value },
          ...buyerSellerDetails,
          additionalDocuments,
          loadingSlipDetails,
          unLoadingSlipDetails,
          ewayLorryReceipt,
          debitNote,
          goodNote,
          otherDetails,
          previousDetails,
          nextDetails,
          onBehalfName,
          materialName,
          buyerName,
          sellerName,
          isInwardTransfer,
          recyclingCertificate,
          goodDetails,
          outputDetails,
        });
      }
    }
    if (e.target.name === 'category') {
      if (basic.role === onBehalfRole.SELLER) {
        setBuyerSellerDetails({
          ...buyerSellerDetails,
          seller_category: e.target.value,
          buyer_category: '',
        });
        if (isSubmitted) {
          validateForm({
            ...{ ...basic, [e.target.name]: e.target.value },
            ...{
              ...buyerSellerDetails,
              seller_category: e.target.value,
              buyer_category: '',
            },
            loadingSlipDetails,
            unLoadingSlipDetails,
            previousDetails,
            nextDetails,
            additionalDocuments,
            ewayLorryReceipt,
            debitNote,
            goodNote,
            otherDetails,
            onBehalfName,
            materialName,
            buyerName,
            sellerName,
            isInwardTransfer,
            recyclingCertificate,
            goodDetails,
            outputDetails,
          });
        }
      } else {
        setBuyerSellerDetails({
          ...buyerSellerDetails,
          buyer_category: e.target.value,
          seller_category: '',
        });
        if (isSubmitted) {
          validateForm({
            ...{ ...basic, [e.target.name]: e.target.value },
            ...{
              ...buyerSellerDetails,
              buyer_category: e.target.value,
              seller_category: '',
            },
            loadingSlipDetails,
            previousDetails,
            nextDetails,
            additionalDocuments,
            unLoadingSlipDetails,
            ewayLorryReceipt,
            debitNote,
            goodNote,
            otherDetails,
            onBehalfName,
            materialName,
            buyerName,
            sellerName,
            isInwardTransfer,
            recyclingCertificate,
            goodDetails,
            outputDetails,
          });
        }
      }
    }
  };

  const handleBuyerSellerChange = (e: any) => {
    setBuyerSellerDetails({
      ...buyerSellerDetails,
      [e.target.name]: e.target.value,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...{ ...buyerSellerDetails, [e.target.name]: e.target.value },
        additionalDocuments,
        loadingSlipDetails,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        debitNote,
        goodNote,
        otherDetails,
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handleBuyerSellerChangeDate = (date: any) => {
    setBuyerSellerDetails({
      ...buyerSellerDetails,
      date: date,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...{ ...buyerSellerDetails, date },
        loadingSlipDetails,
        additionalDocuments,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        debitNote,
        goodNote,
        otherDetails,
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handleBuyerSellerChangePaymentDate = (payment_date: any) => {
    setBuyerSellerDetails({
      ...buyerSellerDetails,
      payment_date: payment_date,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...{ ...buyerSellerDetails, payment_date },
        loadingSlipDetails,
        additionalDocuments,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        debitNote,
        goodNote,
        otherDetails,
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handleLoadingSlipChange = (e: any) => {
    setLoadingSlipDetails({
      ...loadingSlipDetails,
      [e.target.name]: e.target.value,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails: {
          ...loadingSlipDetails,
          [e.target.name]: e.target.value,
        },
        additionalDocuments,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        debitNote,
        goodNote,
        otherDetails,
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handleLoadingSlipChangeDate = (date: any) => {
    setLoadingSlipDetails({
      ...loadingSlipDetails,
      date: date,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails: { ...loadingSlipDetails, date },
        additionalDocuments,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        debitNote,
        goodNote,
        otherDetails,
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handlUnloadingSlipChange = (e: any) => {
    setUnLoadingSlipDetails({
      ...unLoadingSlipDetails,
      [e.target.name]: e.target.value,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        additionalDocuments,
        unLoadingSlipDetails: {
          ...unLoadingSlipDetails,
          [e.target.name]: e.target.value,
        },
        ewayLorryReceipt,
        debitNote,
        otherDetails,
        previousDetails,
        nextDetails,
        goodNote,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handleUnloadingSlipChangeDate = (date: any) => {
    setUnLoadingSlipDetails({
      ...unLoadingSlipDetails,
      date: date,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        additionalDocuments,
        unLoadingSlipDetails: { ...unLoadingSlipDetails, date },
        ewayLorryReceipt,
        debitNote,
        goodNote,
        otherDetails,
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handleEwayChange = (e: any) => {
    setEwayLorryReceipt({
      ...ewayLorryReceipt,
      [e.target.name]: e.target.value,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        unLoadingSlipDetails,
        previousDetails,
        nextDetails,
        additionalDocuments,
        ewayLorryReceipt: {
          ...ewayLorryReceipt,
          [e.target.name]: e.target.value,
        },
        debitNote,
        goodNote,
        otherDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handleEwayChangeDate = (date: any) => {
    setEwayLorryReceipt({
      ...ewayLorryReceipt,
      date: date,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        unLoadingSlipDetails,
        previousDetails,
        nextDetails,
        additionalDocuments,
        ewayLorryReceipt: { ...ewayLorryReceipt, date },
        debitNote,
        goodNote,
        otherDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handleDebitNoteChange = (e: any) => {
    setDebitNotet({
      ...debitNote,
      [e.target.name]: e.target.value,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        additionalDocuments,
        debitNote: { ...debitNote, [e.target.name]: e.target.value },
        goodNote,
        otherDetails,
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handleGoodNoteChange = (e: any) => {
    setGoodNote({
      ...goodNote,
      [e.target.name]: e.target.value,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        additionalDocuments,
        debitNote,
        goodNote: { ...goodNote, [e.target.name]: e.target.value },
        otherDetails,
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handleRecyclingCertificateChange = (e: any) => {
    setRecyclingCertificate({
      ...recyclingCertificate,
      [e.target.name]: e.target.value,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        debitNote,
        goodNote,
        recyclingCertificate: {
          ...recyclingCertificate,
          [e.target.name]: e.target.value,
        },
        otherDetails,
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handleGoodDetails = (e: any) => {
    setGoodDetails({
      ...goodDetails,
      [e.target.name]: e.target.value,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        additionalDocuments,
        debitNote,
        goodNote,
        otherDetails,
        recyclingCertificate,
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        goodDetails: { ...goodDetails, [e.target.name]: e.target.value },
        outputDetails,
      });
    }
  };

  const handleOutputDetails = (e: any) => {
    setOutputDetails({
      ...outputDetails,
      [e.target.name]: e.target.value,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        additionalDocuments,
        debitNote,
        goodNote,
        otherDetails,
        recyclingCertificate,
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        goodDetails,
        outputDetails: { ...outputDetails, [e.target.name]: e.target.value },
      });
    }
  };

  const handleOtherChange = (e: any) => {
    setOtherDetails({
      ...otherDetails,
      [e.target.name]: e.target.value,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        additionalDocuments,
        debitNote,
        goodNote,
        otherDetails: { ...otherDetails, [e.target.name]: e.target.value },
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handleNextChange = (e: any) => {
    setNextDetails({
      ...nextDetails,
      [e.target.name]: e.target.value,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        additionalDocuments,
        debitNote,
        goodNote,
        otherDetails,
        nextDetails: { ...nextDetails, [e.target.name]: e.target.value },
        previousDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handleNextChangeDate = (date: any) => {
    setNextDetails({
      ...nextDetails,
      seller_invoice_date: date,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        additionalDocuments,
        debitNote,
        goodNote,
        otherDetails,
        nextDetails: { ...nextDetails, seller_invoice_date: date },
        previousDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handlePreviousChange = (e: any) => {
    setPreviousDetails({
      ...previousDetails,
      [e.target.name]: e.target.value,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        additionalDocuments,
        debitNote,
        goodNote,
        otherDetails,
        previousDetails: {
          ...previousDetails,
          [e.target.name]: e.target.value,
        },
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handlePreviousChangeDate = (date: any) => {
    setPreviousDetails({
      ...previousDetails,
      seller_invoice_date: date,
    });
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        additionalDocuments,
        debitNote,
        goodNote,
        otherDetails,
        previousDetails: { ...previousDetails, seller_invoice_date: date },
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };

  const handlMaterialName = (option: any) => {
    if (option !== null) {
      let materialData = {
        id: option.value === option.label ? '0' : option.value,
        name: option.label,
        added_by: 1,
        category_id: basic.material_type,
      };
      setMaterialNameInfo(materialData);
      setSelectMaterialName(option);
      setMaterialName(option.value);
      if (isSubmitted) {
        validateForm({
          ...basic,
          ...buyerSellerDetails,
          loadingSlipDetails,
          unLoadingSlipDetails,
          additionalDocuments,
          ewayLorryReceipt,
          debitNote,
          goodNote,
          otherDetails,
          previousDetails,
          nextDetails,
          onBehalfName,
          materialName: option.value,
          buyerName,
          sellerName,
          isInwardTransfer,
          recyclingCertificate,
          goodDetails,
          outputDetails,
        });
      }
    } else {
      setMaterialNameInfo({});
      setSelectMaterialName(option);
      setMaterialName('');
    }
  };

  const handleOnBehalfName = (option: any) => {
    if (option !== null) {
      let onBehalfData = {
        id: option.value === option.label ? '0' : option.value,
        name: option.label,
        added_by: 1,
      };
      setOnBehalfNameInfo(onBehalfData);
      setSelectOnBehalfName(option);
      setOnBehalfName(option.value);
      if (basic.role === onBehalfRole.SELLER) {
        setSellerNameInfo(onBehalfData);
        setSelectSellerName(option);
        setSellerName(option.value);
        if (isSubmitted) {
          validateForm({
            ...basic,
            ...buyerSellerDetails,
            loadingSlipDetails,
            unLoadingSlipDetails,
            additionalDocuments,
            ewayLorryReceipt,
            debitNote,
            goodNote,
            otherDetails,
            previousDetails,
            nextDetails,
            onBehalfName: option.value,
            materialName,
            buyerName,
            sellerName: option.value,
            isInwardTransfer,
            recyclingCertificate,
            goodDetails,
            outputDetails,
          });
        }
      } else {
        setBuyerNameInfo(onBehalfData);
        setSelectBuyerName(option);
        setBuyerName(option.value);
        if (isSubmitted) {
          validateForm({
            ...basic,
            ...buyerSellerDetails,
            loadingSlipDetails,
            unLoadingSlipDetails,
            ewayLorryReceipt,
            additionalDocuments,
            debitNote,
            goodNote,
            onBehalfName: option.value,
            otherDetails,
            previousDetails,
            nextDetails,
            materialName,
            buyerName: option.value,
            sellerName,
            isInwardTransfer,
            recyclingCertificate,
            goodDetails,
            outputDetails,
          });
        }
      }
    } else {
      setOnBehalfNameInfo({});
      setSelectOnBehalfName(option);
      setOnBehalfName('');
      setBuyerNameInfo({});
      setSelectBuyerName(null);
      setBuyerName('');
      setSellerNameInfo({});
      setSelectSellerName(null);
      setSellerName('');
    }
  };

  const handleBuyerName = (option: any) => {
    if (option !== null) {
      let buyerData = {
        id: option.value === option.label ? '0' : option.value,
        name: option.label,
        added_by: 1,
      };
      setBuyerNameInfo(buyerData);
      setSelectBuyerName(option);
      setBuyerName(option.value);
      if (isSubmitted) {
        validateForm({
          ...basic,
          ...buyerSellerDetails,
          loadingSlipDetails,
          unLoadingSlipDetails,
          additionalDocuments,
          ewayLorryReceipt,
          debitNote,
          goodNote,
          otherDetails,
          previousDetails,
          nextDetails,
          onBehalfName: option.value,
          materialName,
          buyerName: option.value,
          sellerName,
          isInwardTransfer,
          recyclingCertificate,
          goodDetails,
          outputDetails,
        });
      }
    } else {
      setBuyerNameInfo({});
      setSelectBuyerName(option);
      setBuyerName('');
    }
  };

  const handleSellerName = (option: any) => {
    if (option !== null) {
      let sellerData = {
        id: option.value === option.label ? '0' : option.value,
        name: option.label,
        added_by: 1,
      };
      setSellerNameInfo(sellerData);
      setSelectSellerName(option);
      setSellerName(option.value);
      if (isSubmitted) {
        validateForm({
          ...basic,
          ...buyerSellerDetails,
          loadingSlipDetails,
          unLoadingSlipDetails,
          additionalDocuments,
          ewayLorryReceipt,
          debitNote,
          goodNote,
          onBehalfName: option.value,
          otherDetails,
          previousDetails,
          nextDetails,
          materialName,
          buyerName,
          sellerName: option.value,
          isInwardTransfer,
          recyclingCertificate,
          goodDetails,
          outputDetails,
        });
      }
    } else {
      setSellerNameInfo({});
      setSelectSellerName(option);
      setSellerName('');
    }
  };

  const handleAssociatedProjectName = (option: any) => {
    if (option !== null) {
      let sellerData = {
        id: option.value === option.label ? '0' : option.value,
        name: option.label,
        added_by: 1,
      };
      setAssociatedProjectNameInfo(sellerData);
      option.value && setSelectAssociatedProjectName(option);
      option.value && setAssociatedProjectName(option.value);
      if (isSubmitted) {
        validateForm({
          ...basic,
          ...buyerSellerDetails,
          loadingSlipDetails,
          unLoadingSlipDetails,
          additionalDocuments,
          ewayLorryReceipt,
          debitNote,
          goodNote,
          onBehalfName,
          otherDetails,
          previousDetails,
          nextDetails,
          materialName,
          buyerName,
          sellerName,
          associatedProjectName: option.value,
          isInwardTransfer,
          recyclingCertificate,
          goodDetails,
          outputDetails,
        });
      }
    } else {
      setAssociatedProjectNameInfo({});
      setSelectAssociatedProjectName(option);
      setAssociatedProjectName('');
    }
  };

  const handlRoleWiseDisbale = (e: any) => {
    if (e.target.value === onBehalfRole.SELLER) {
      setSellerNameInfo(onBehalfNameInfo);
      setSelectSellerName(selectOnBehalfName);
      setSellerName(onBehalfName);
      setBuyerNameInfo({});
      setSelectBuyerName(null);
      setBuyerName('');
      setBuyerSellerDetails({
        ...buyerSellerDetails,
        seller_category: basic.category,
        buyer_category: '',
      });
      if (isSubmitted) {
        validateForm({
          ...{ ...basic, [e.target.name]: e.target.value },
          loadingSlipDetails,
          ...{
            ...buyerSellerDetails,
            seller_category: basic.category,
            buyer_category: '',
          },
          additionalDocuments,
          unLoadingSlipDetails,
          ewayLorryReceipt,
          debitNote,
          goodNote,
          otherDetails,
          previousDetails,
          nextDetails,
          onBehalfName,
          materialName,
          buyerName: '',
          sellerName: onBehalfName,
          isInwardTransfer,
          recyclingCertificate,
          goodDetails,
          outputDetails,
        });
      }
    } else {
      setBuyerNameInfo(onBehalfNameInfo);
      setSelectBuyerName(selectOnBehalfName);
      setBuyerName(onBehalfName);
      setSellerNameInfo({});
      setSelectSellerName(null);
      setSellerName('');
      setBuyerSellerDetails({
        ...buyerSellerDetails,
        buyer_category: basic.category,
        seller_category: '',
      });
      if (isSubmitted) {
        validateForm({
          ...{ ...basic, [e.target.name]: e.target.value },
          loadingSlipDetails,
          ...{
            ...buyerSellerDetails,
            buyer_category: basic.category,
            seller_category: '',
          },
          unLoadingSlipDetails,
          ewayLorryReceipt,
          debitNote,
          goodNote,
          otherDetails,
          previousDetails,
          nextDetails,
          additionalDocuments,
          onBehalfName,
          materialName,
          buyerName: onBehalfName,
          sellerName: '',
          isInwardTransfer,
          recyclingCertificate,
          goodDetails,
          outputDetails,
        });
      }
    }
  };

  const handleSellerBuyerLatlng = () => {
    if (isBuyerLocation && !id && buyerLocationText) {
      setOtherDetails({
        ...otherDetails,
        buyer_main_address: buyerLocationText,
        buyer_location:
          buyerLocation.lat.toString() + ',' + buyerLocation.lng.toString(),
      });
    } else {
      if (isSellerLocation && !id && sellerLocationText) {
        setOtherDetails({
          ...otherDetails,
          seller_main_address: sellerLocationText,
          seller_location:
            sellerLocation.lat.toString() + ',' + sellerLocation.lng.toString(),
        });
      }
    }
  };

  const getDataTrasactionById = async () => {
    setIsLoading(true);
    let path = apiUrl.getTrasactionListById + id;
    const fr = await helper.getData(path);
    const res = await fr.response;
    if (res.success) {
      const trasactionData = res?.data?.transaction;
      getUpdateData(trasactionData);
      setIsLoading(false);
    } else {
      toast.error(res.message);
    }
    setIsLoading(false);
  };

  const getUpdateData = async (trasactionData: any) => {
    if (id && trasactionData) {
      let transactionUpdateData = trasactionData;
      let onBehalOption: any = {
        value: transactionUpdateData?.onbehalf?.id,
        label: transactionUpdateData?.onbehalf?.name,
      };
      let onBuyerOption: any = {
        value: transactionUpdateData?.buyer?.id,
        label: transactionUpdateData?.buyer?.name,
      };
      let onSellerOption: any = {
        value: transactionUpdateData?.seller?.id,
        label: transactionUpdateData?.seller?.name,
      };

      let onAssociatedProjectOption: any =
        transactionUpdateData.associated_project
          ? {
              value: transactionUpdateData?.associated_project?.id,
              label: transactionUpdateData?.associated_project?.name,
            }
          : null;
      let onMaterialOption: any = {
        value: transactionUpdateData?.material_name_type_data?.id,
        label: transactionUpdateData?.material_name_type_data?.name,
      };
      setIsInwardTransfer(transactionUpdateData?.is_inward_transfer);
      const buyerSellerDate =
        transactionUpdateData?.details?.buyer_and_seller_details?.date != null
          ? new Date(
              transactionUpdateData?.details?.buyer_and_seller_details?.date
            )
          : null;
      const buyerSellerPaymentDate =
        transactionUpdateData?.details?.buyer_and_seller_details
          ?.payment_date != null
          ? new Date(
              transactionUpdateData?.details?.buyer_and_seller_details?.payment_date
            )
          : null;
      const loadingDate =
        transactionUpdateData?.details?.loading_weight_slip_details?.date !=
        null
          ? new Date(
              transactionUpdateData?.details?.loading_weight_slip_details?.date
            )
          : null;
      const unLoadingDate =
        transactionUpdateData?.details?.unloading_weight_slip_details?.date !=
        null
          ? new Date(
              transactionUpdateData?.details?.unloading_weight_slip_details?.date
            )
          : null;
      const ewayDate =
        transactionUpdateData?.details?.eway_or_lorry_receipt?.date != null
          ? new Date(
              transactionUpdateData?.details?.eway_or_lorry_receipt?.date
            )
          : null;
      const nextDate =
        transactionUpdateData?.details?.next_stage_transaction_info
          ?.seller_invoice_date != null
          ? new Date(
              transactionUpdateData?.details?.next_stage_transaction_info?.seller_invoice_date
            )
          : null;
      const prevDate =
        transactionUpdateData?.details?.previous_stage_transaction_info
          ?.seller_invoice_date != null
          ? new Date(
              transactionUpdateData?.details?.previous_stage_transaction_info?.seller_invoice_date
            )
          : null;
      setBasic(transactionUpdateData?.details?.basic_details);
      setBuyerSellerDetails({
        ...transactionUpdateData?.details?.buyer_and_seller_details,
        date: buyerSellerDate,
        payment_date: buyerSellerPaymentDate,
      });
      setLoadingSlipDetails({
        ...transactionUpdateData?.details?.loading_weight_slip_details,
        date: loadingDate,
      });
      setUnLoadingSlipDetails({
        ...transactionUpdateData?.details?.unloading_weight_slip_details,
        date: unLoadingDate,
      });
      setEwayLorryReceipt({
        ...transactionUpdateData?.details?.eway_or_lorry_receipt,
        date: ewayDate,
      });
      setDebitNotet(transactionUpdateData?.details?.debit_note_for_buyer);
      setGoodNote(transactionUpdateData?.details?.good_recived_note);
      setGoodDetails(transactionUpdateData?.details?.good_transfer_details);
      setOutputDetails(transactionUpdateData?.details?.output_material);
      setOtherDetails(transactionUpdateData?.details?.other_details);
      setNextDetails({
        ...transactionUpdateData?.details?.next_stage_transaction_info,
        seller_invoice_date: nextDate,
      });
      setPreviousDetails({
        ...transactionUpdateData?.details?.previous_stage_transaction_info,
        seller_invoice_date: prevDate,
      });
      setAdditionalDocuments(
        transactionUpdateData?.details?.additional_documents &&
          transactionUpdateData?.details?.additional_documents.length > 0
          ? transactionUpdateData?.details?.additional_documents
          : [additionalDocumentInfo]
      );
      setRecyclingCertificate(
        transactionUpdateData?.details?.recycling_certificate
      );
      handleOnBehalfName(onBehalOption);
      handleBuyerName(onBuyerOption);
      handleSellerName(onSellerOption);
      handleAssociatedProjectName(onAssociatedProjectOption);
      handlMaterialName(onMaterialOption);
    }
  };

  useEffect(() => {
    if (id) {
      getDataTrasactionById();
    }
  }, []);

  const handleAdditionalDocumentInfoChange = (
    e: any,
    index: any,
    base64: any = ''
  ) => {
    let payload = additionalDocuments[index];
    payload = {
      ...payload,
      [e.target.name]: e.target.value,
    };
    if (base64) {
      payload = {
        ...payload,
        document: base64,
        document_file_name: e.target.value.name,
      };
    }
    additionalDocuments[index] = payload;
    setAdditionalDocuments([...additionalDocuments]);
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        loadingSlipDetails,
        additionalDocuments: [...additionalDocuments],
        unLoadingSlipDetails,
        ewayLorryReceipt,
        debitNote,
        otherDetails,
        previousDetails,
        nextDetails,
        goodNote,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        goodDetails,
        outputDetails,
      });
    }
  };
  const handleSelectedFile = (e: any, index: any = null, fileName?: string) => {
    let name = e.target.name;
    let file = e.target.files[0];
    const maxFileSize = 5000000;
    if (file.size >= maxFileSize) {
      toast.error(translate('ERRORS.INVALID.DOCUMENT_VALIDATION'));
      return;
    }
    if (!isValidFile(file.type)) {
      toast.error(translate('ERRORS.INVALID.INVALID_DOCUMENT'));
      return;
    }
    switch (name) {
      case transactionRegistryFileUploadSections.invoice:
        handleFileUploder(file, name, fileType.Invoice);
        break;
      case transactionRegistryFileUploadSections.loadWeightSlip:
        handleFileUploder(file, name, fileType.LoadingSlip);
        break;
      case transactionRegistryFileUploadSections.unLoadWeightSlip:
        handleFileUploder(file, name, fileType.UnLoadingSlip);
        break;
      case transactionRegistryFileUploadSections.ewayLorryReceipt:
        handleFileUploder(file, name, fileType.EWayReceipt);
        break;
      case transactionRegistryFileUploadSections.debitNote:
        handleFileUploder(file, name, fileType.Others);
        break;
      case transactionRegistryFileUploadSections.GRN:
        handleFileUploder(file, name, fileType.Others);
        break;
      case transactionRegistryFileUploadSections.additionalDocuments:
        handleFileUploder(file, name, fileType.Others, index, fileName);
        break;
      case transactionRegistryFileUploadSections.recyclingCertificate:
        handleFileUploder(file, name, fileType.RecyclingCertificate);
        break;
      default:
    }
  };
  const handleFileUploder = async (
    file: any,
    name: string,
    fileType: string,
    index: any = null,
    fileName?: string
  ) => {
    let formData = new FormData();
    if (fileName) {
      formData.append('file', file, fileName);
    } else {
      formData.append('file', file);
    }
    formData.append('file_type', fileType);
    let path = apiUrl.file_upload;
    const fr = await helper.formPost(formData, path);
    const res = await fr.response;
    if (res.success) {
      toast.success(res.message);
      getFileUploaderData(res?.data?.data, name, index);
    } else {
      toast.error(res.message);
    }
  };

  const getFileUploaderData = (data: any, name: string, index: any) => {
    switch (name) {
      case transactionRegistryFileUploadSections.invoice:
        setBuyerSellerDetails({
          ...buyerSellerDetails,
          invoice: data?.document_path,
          invoice_id: data?.id,
          invoice_file_name: data?.document_name,
        });
        break;
      case transactionRegistryFileUploadSections.loadWeightSlip:
        setLoadingSlipDetails({
          ...loadingSlipDetails,
          loading_weight_slip: data?.document_path,
          loading_weight_slip_id: data?.id,
          loading_weight_slip_file_name: data?.document_name,
        });
        break;
      case transactionRegistryFileUploadSections.unLoadWeightSlip:
        setUnLoadingSlipDetails({
          ...unLoadingSlipDetails,
          unloading_weight_slip: data?.document_path,
          unloading_weight_slip_id: data?.id,
          unloading_weight_slip_file_name: data?.document_name,
        });
        break;
      case transactionRegistryFileUploadSections.ewayLorryReceipt:
        setEwayLorryReceipt({
          ...ewayLorryReceipt,
          eway_or_lorry_reciept: data?.document_path,
          eway_or_lorry_reciept_id: data?.id,
          eway_or_lorry_reciept_file_name: data?.document_name,
        });
        break;
      case transactionRegistryFileUploadSections.debitNote:
        setDebitNotet({
          ...debitNote,
          debit_note: data?.document_path,
          debit_note_id: data?.id,
          debit_note_file_name: data?.document_name,
        });
        break;
      case transactionRegistryFileUploadSections.GRN:
        setGoodNote({
          ...goodNote,
          GRN: data?.document_path,
          GRN_ID: data?.id,
          GRN__file_name: data?.document_name,
        });
        break;
      case transactionRegistryFileUploadSections.additionalDocuments:
        let document_details = {
          ...additionalDocuments[index],
          document: data?.document_path,
          document_id: data?.id,
          document_file_name: data?.document_name,
        };
        additionalDocuments[index] = document_details;
        setAdditionalDocuments([...additionalDocuments]);
        if (isSubmitted) {
          validateForm({
            ...basic,
            ...buyerSellerDetails,
            loadingSlipDetails,
            additionalDocuments: [...additionalDocuments],
            unLoadingSlipDetails,
            ewayLorryReceipt,
            debitNote,
            otherDetails,
            previousDetails,
            nextDetails,
            goodNote,
            onBehalfName,
            materialName,
            buyerName,
            sellerName,
            goodDetails,
            outputDetails,
          });
        }
        break;
      case transactionRegistryFileUploadSections.recyclingCertificate:
        setRecyclingCertificate({
          ...recyclingCertificate,
          recycling_certificate_document: data?.document_path,
          recycling_certificate_document_id: data?.id,
          recycling_certificate_document_file_name: data?.document_name,
        });
        break;
      default:
    }
  };
  const validateForm = (userInfo: any, formSubmit = false) => {
    const errs: any = transactionRequestValidator(errors, userInfo);
    setErrors(errs);
    const isFormValid = isEmptyDeep(errs);
    if (formSubmit && !isFormValid) {
      toast.error(translate('ERRORS.REQUIRED.MANDATORY'));
      return;
    }
    if (formSubmit && isFormValid) {
      setSubmitPopup(true);
    }
  };
  const onSubmit = async (e: any) => {
    let updatedAdditionalDocument = [];
    for (let i = 0; i < additionalDocuments.length; i++) {
      let checkIfEmpty = isEmptyDeep(additionalDocuments[i]);
      if (!checkIfEmpty) {
        const splittedArray =
          additionalDocuments[i].document_file_name.split('.');
        const newName =
          additionalDocuments[i].label +
          '.' +
          splittedArray[splittedArray.length - 1];
        updatedAdditionalDocument.push({
          ...additionalDocuments[i],
          document_file_name: newName,
        });
      }
    }
    let postJson = {
      details: {
        basic_details: basic,
        buyer_and_seller_details: buyerSellerDetails,
        loading_weight_slip_details: loadingSlipDetails,
        unloading_weight_slip_details: unLoadingSlipDetails,
        eway_or_lorry_receipt: ewayLorryReceipt,
        debit_note_for_buyer: debitNote,
        good_recived_note: goodNote,
        other_details: otherDetails,
        next_stage_transaction_info: nextDetails,
        previous_stage_transaction_info: previousDetails,
        additional_documents: updatedAdditionalDocument,
        recycling_certificate: recyclingCertificate,
        good_transfer_details: goodDetails,
        output_material: outputDetails,
      },
      material_name_type_info: materialNameInfo,
      onbehalfof_info: onBehalfNameInfo,
      buyer_info: buyerNameInfo,
      seller_info: sellerNameInfo,
      accosiated_project_info: associatedProjectNameInfo,
      user_id: 1,
      action_by: 1,
      utility_id: Number(basic.utility),
      material_type_id: Number(basic.material_type),
      is_inward_transfer: isInwardTransfer,
      is_verified: true,
    };
    let updateJson = {
      ...postJson,
      material_name_type_info: { ...materialNameInfo, is_active: true },
      onbehalfof_info: { ...onBehalfNameInfo, is_active: true },
      buyer_info: { ...buyerNameInfo, is_active: true },
      seller_info: { ...sellerNameInfo, is_active: true },
      accosiated_project_info: {
        ...associatedProjectNameInfo,
        is_active: true,
      },
      id: Number(id),
    };
    if (id) {
      const response = await updateTransaction(updateJson).unwrap();
      if (response.success) {
        toast.success(response.message);
        navigate(routesConstant.transactionList);
      } else {
        toast.error(response.message);
      }
    } else {
      const response = await createTransaction(postJson).unwrap();
      if (response.success) {
        toast.success(response.message);
        navigate(routesConstant.transactionList);
      } else {
        toast.error(response.message);
      }
    }
  };

  const onDisCard = () => {
    if (id) {
      navigate(routesConstant.transactionList);
    } else {
      setIsSubmited(false);
      setErrors(errorJson);
      setBasic(basicDetails);
      setBuyerSellerDetails(buyerAndSellerDetails);
      setLoadingSlipDetails(loadingWeightSlipDetails);
      setUnLoadingSlipDetails(unloadingweightSlipDetails);
      setEwayLorryReceipt(ewayOrLorryReceipt);
      setDebitNotet(debitNoteForBuyer);
      setGoodNote(goodRecivedNote);
      setGoodDetails(goodTransferDetails);
      setOutputDetails(outputMaterialDetails);
      setOtherDetails(otherData);
      setSellerNameInfo({});
      setSelectSellerName(null);
      setSellerName('');
      setBuyerNameInfo({});
      setSelectBuyerName(null);
      setBuyerName('');
      setOnBehalfNameInfo({});
      setSelectOnBehalfName(null);
      setOnBehalfName('');
      setMaterialNameInfo({});
      setSelectMaterialName(null);
      setMaterialName('');
      setNextDetails(NexStageTransactionInfo);
      setPreviousDetails(PreviousStageTransactionInfo);
    }
  };
  const onChangeIsRegular = (e: any) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
    const checkValue = e.target.checked;
    setIsInwardTransfer(checkValue);
    onDisCard();
    if (isSubmitted) {
      validateForm({
        ...basic,
        ...buyerSellerDetails,
        otherDetails,
        loadingSlipDetails,
        unLoadingSlipDetails,
        ewayLorryReceipt,
        debitNote,
        goodNote,
        previousDetails,
        nextDetails,
        onBehalfName,
        materialName,
        buyerName,
        sellerName,
        isInwardTransfer: checkValue,
        recyclingCertificate,
        goodDetails,
        outputDetails,
      });
    }
  };
  return (
    <React.Fragment>
      <div
        className={`container body-content-main ${isLoading ? 'loading' : ''}`}
      >
        <div className='panel-main'>
          <div className='title-center-main'>
            <h2 className='panel-title-main'>
              {id
                ? translate('TRANSACTION_REGISTRY_PAGE.TITLES.VIEW_UPDATE')
                : translate('TRANSACTION_REGISTRY_PAGE.TITLES.CREATE')}{' '}
              {translate(
                'TRANSACTION_REGISTRY_PAGE.TITLES.TRANSACTION_REGISTRY'
              )}
            </h2>
            <div className='toggle-button-light'>
              <div className='form-group required '>
                <div className='toggle-button big '>
                  <div
                    className={`form-group-switch ${
                      isInwardTransfer ? 'checked' : ''
                    }`}
                  >
                    <span className='left'>
                      {translate('TRANSACTION_REGISTRY_PAGE.TITLES.REGULAR')}
                    </span>
                    <label className='switch '>
                      <input
                        type='checkbox'
                        name='is_regular_transfered'
                        id='is_regular_transfered'
                        checked={isInwardTransfer}
                        onChange={onChangeIsRegular}
                        // disabled={id ? true : false}
                      />
                      <span className='slider round' />
                    </label>
                    <span className='right'>
                      {translate(
                        'TRANSACTION_REGISTRY_PAGE.TITLES.INWARD_TRANSFER'
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Form
            className='transaction-registry-form'
            // id="transactionregistryform"
            noValidate
            validated={validated}
          >
            <div className='panel-box-main'>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate('TRANSACTION_REGISTRY_PAGE.TITLES.BASIC_DETAILS')}
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.ON_BEHALF'
                          )}
                        </Form.Label>
                        <CreatableSelect
                          isClearable
                          value={selectOnBehalfName}
                          onChange={handleOnBehalfName}
                          options={valueChainData}
                           isDisabled={state.auth?.user?.value_chain_id !== null && userType.VALUE_CHAIN_PARTNER == state.auth?.user?.userTypeId}
                          placeholder={translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.ENTER_USER_FULL_NAME'
                          )}
                        />
                        <Form.Control
                          tabIndex={-1}
                          type='text'
                          autoComplete='off'
                          value={onBehalfName}
                          required
                          style={{ opacity: 0, height: 0, display: 'none' }}
                          onChange={() => {}}
                        />
                        {
                          <span className='error'>
                            {translate(errors.onBehalfName)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate('TRANSACTION_REGISTRY_PAGE.TITLES.ROLE')}
                        </Form.Label>
                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='role'
                              id='seller-button-icon'
                              value={onBehalfRole.SELLER}
                              checked={basic.role === onBehalfRole.SELLER}
                              onChange={(e) => {
                                handleBasicChange(e);
                                handlRoleWiseDisbale(e);
                              }}
                            />
                            <Form.Label
                              className='form-check-label'
                              htmlFor='seller-button-icon'
                            >
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER'
                              )}
                            </Form.Label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='role'
                              id='buyer-button-icon'
                              value={onBehalfRole.BUYER}
                              checked={basic.role === onBehalfRole.BUYER}
                              onChange={(e) => {
                                handleBasicChange(e);
                                handlRoleWiseDisbale(e);
                              }}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='buyer-button-icon'
                            >
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.BUYER'
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.CATEGORY'
                          )}
                        </Form.Label>
                        <Form.Select
                          className='form-control'
                          name='category'
                          value={basic.category}
                          onChange={(e) => {
                            handleBasicChange(e);
                          }}
                          required
                        >
                          <option value={''}>
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_TRANSACTION_CATEGORY'
                            )}
                          </option>
                          {categoryData?.length > 0 &&
                            categoryData?.map((item: any, index: number) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.category)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.UTILITY'
                          )}
                        </Form.Label>
                        <Form.Select
                          className='form-control'
                          name='utility'
                          value={basic.utility}
                          onChange={(e) => {
                            handleBasicChange(e);
                          }}
                          required
                        >
                          <option value={''}>
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_UTILITY'
                            )}
                          </option>
                          {utilityData?.length > 0 &&
                            utilityData?.map((item: any, index: number) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.utility)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.MATERIAL_TYPE'
                          )}
                        </Form.Label>
                        <Form.Select
                          className='form-control'
                          name='material_type'
                          value={basic.material_type}
                          onChange={(e) => {
                            handleBasicChange(e);
                          }}
                          // disabled={id ? true : false}
                          required
                        >
                          <option value={''}>
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_MATERIAL_TYPE'
                            )}
                          </option>
                          {materialTypeData?.length > 0 &&
                            materialTypeData?.map(
                              (item: any, index: number) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              }
                            )}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.material_type)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group required'>
                        <label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.MATERIAL_SUB_TYPE'
                          )}
                        </label>
                        <CreatableSelect
                          isClearable
                          value={selectMaterialName}
                          onChange={handlMaterialName}
                          options={materialSubTypeData}
                          placeholder={translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_MATERIAL_SUB_TYPE'
                          )}
                          // isDisabled={id ? true : false}
                        />
                        <Form.Control
                          tabIndex={-1}
                          type='text'
                          autoComplete='off'
                          value={materialName}
                          required
                          style={{ opacity: 0, height: 0, display: 'none' }}
                          onChange={() => {}}
                        />
                        {
                          <span className='error'>
                            {translate(errors.materialName)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.HSN_CODE'
                          )}
                        </Form.Label>
                        <input
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.HSN_CODE'
                            ) + ''
                          }
                          name='hsn_code'
                          value={basic.hsn_code}
                          onChange={(e) => {
                            handleBasicChange(e);
                          }}
                          maxLength={commanValidation.hsnMax}
                          // disabled={id ? true : false}
                          required
                        />
                        {
                          <span className='error'>
                            {translate(errors.hsn_code)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.LOT_BATCH_PREFERENCE'
                          )}
                        </Form.Label>
                        <input
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.LOT_BATCH_PREFERENCE'
                            ) + ''
                          }
                          name='lot_batch_preference'
                          value={basic.lot_batch_preference}
                          onChange={(e) => {
                            handleBasicChange(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate('TRANSACTION_REGISTRY_PAGE.TITLES.COLOR')}
                        </Form.Label>
                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='material_color'
                              id='natural-button-icon'
                              value={ColorType.Natural}
                              checked={
                                (basic.material_color).toLowerCase() === ColorType.Natural
                              }
                              onChange={(e) => {
                                handleBasicChange(e);
                              }}
                              // disabled={id ? true : false}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='natural-button-icon'
                            >
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.NATURAL'
                              )}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='material_color'
                              id='color-button-icon'
                              value={ColorType.Colored}
                              checked={
                                (basic.material_color).toLowerCase() === ColorType.Colored
                              }
                              onChange={(e) => {
                                handleBasicChange(e);
                              }}
                              // disabled={id ? true : false}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='color-button-icon'
                            >
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.COLOUR'
                              )}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='material_color'
                              id='mixed-button-icon'
                              value={ColorType.Mixed}
                              checked={(basic.material_color).toLowerCase() === ColorType.Mixed}
                              onChange={(e) => {
                                handleBasicChange(e);
                              }}
                              // disabled={id ? true : false}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='mixed-button-icon'
                            >
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.MIXED'
                              )}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='material_color'
                              id='na-button-icon'
                              value={ColorType.NA}
                              checked={basic.material_color === ColorType.NA}
                              onChange={(e) => {
                                handleBasicChange(e);
                              }}
                              // disabled={id ? true : false}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='na-button-icon'
                            >
                              {translate('TRANSACTION_REGISTRY_PAGE.TITLES.NA')}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.IS_OCEAN_BOUND'
                          )}
                        </Form.Label>
                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='is_ocean_bound'
                              id='yes-button-icon'
                              value='true'
                              checked={basic.is_ocean_bound === 'true'}
                              onChange={(e) => {
                                handleBasicChange(e);
                              }}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='yes-button-icon'
                            >
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.YES'
                              )}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='is_ocean_bound'
                              id='no-button-icon'
                              value='false'
                              checked={basic.is_ocean_bound !== 'true'}
                              onChange={(e) => {
                                handleBasicChange(e);
                              }}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='no-button-icon'
                            >
                              {translate('TRANSACTION_REGISTRY_PAGE.TITLES.NO')}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.ASSOCIATED_PROJECT'
                          )}
                        </Form.Label>
                        <CreatableSelect
                          isClearable
                          value={selectAssociatedProjectName}
                          onChange={handleAssociatedProjectName}
                          options={associatedProjectData}
                          placeholder={translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.ENTER_PROJECT_NAME'
                          )}
                        />
                        <Form.Control
                          tabIndex={-1}
                          type='text'
                          autoComplete='off'
                          value={associatedProjectName}
                          required
                          style={{ opacity: 0, height: 0, display: 'none' }}
                          onChange={() => {}}
                        />
                        {
                          <span className='error'>
                            {translate(errors.onBehalfName)}
                          </span>
                        }
                      </div>
                    </div>

                    <div className='text-area-content'>
                      <div className='form-group group-i-input required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.WASTE_DESCRIPTION'
                          )}
                        </Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.WASTE_DESCRIPTION'
                            ) + ''
                          }
                          name='waste_description'
                          value={basic.waste_description}
                          onChange={(e) => {
                            handleBasicChange(e);
                          }}
                          maxLength={commanValidation.maxLengthAddress}
                          // disabled={id ? true : false}
                          required
                        ></Form.Control>
                        {
                          <span className='error'>
                            {translate(errors.waste_description)}
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate(
                    'TRANSACTION_REGISTRY_PAGE.TITLES.BUYER_AND_SELLER_DETAILS'
                  )}
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.BUYER_NAME'
                          )}
                        </Form.Label>
                        <CreatableSelect
                          isClearable
                          value={selectBuyerName}
                          onChange={handleBuyerName}
                          options={valueChainData}
                          placeholder={translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.ENTER_BUYER_FULL_NAME'
                          )}
                          isDisabled={
                            basic.role === onBehalfRole.BUYER ? true : false
                          }
                        />
                        <Form.Control
                          tabIndex={-1}
                          type='text'
                          autoComplete='off'
                          value={buyerName}
                          required
                          style={{ opacity: 0, height: 0, display: 'none' }}
                          disabled={
                            basic.role === onBehalfRole.BUYER ? true : false
                          }
                          onChange={() => {}}
                        />
                        {
                          <span className='error'>
                            {translate(errors.buyerName)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.BUYER_CATEGORY'
                          )}
                        </Form.Label>
                        <Form.Select
                          className='form-control'
                          name='buyer_category'
                          value={buyerSellerDetails.buyer_category}
                          onChange={(e) => {
                            handleBuyerSellerChange(e);
                          }}
                          disabled={
                            basic.role === onBehalfRole.BUYER ? true : false
                          }
                          required
                        >
                          <option value={''}>
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_BUYER_CATEGORY'
                            )}
                          </option>
                          {categoryData?.length > 0 &&
                            categoryData?.map((item: any, index: number) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.buyer_category)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.BUYER_CONTACT_NUMBER'
                          )}
                        </Form.Label>
                        <input
                          type='number'
                          className='form-control'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.BUYER_CONTACT_NUMBER'
                            ) + ''
                          }
                          autoComplete='off'
                          name='buyer_mobile_no'
                          pattern={commanValidation.numberPattern}
                          max={commanValidation.mobileMaxLength}
                          min={commanValidation.mobileMinLength}
                          value={buyerSellerDetails.buyer_mobile_no}
                          onChange={(e) => {
                            handleBuyerSellerChange(e);
                          }}
                        />
                        {
                          <span className='error'>
                            {translate(errors.buyer_mobile_no)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.BUYER_EMAIL'
                          )}
                        </Form.Label>
                        <input
                          type='email'
                          className='form-control'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.BUYER_EMAIL'
                            ) + ''
                          }
                          autoComplete='off'
                          maxLength={commanValidation.maxLength}
                          minLength={commanValidation.minLengthEmail}
                          name='buyer_email_id'
                          value={buyerSellerDetails.buyer_email_id}
                          onChange={(e) => {
                            handleBuyerSellerChange(e);
                            e.target.checkValidity();
                          }}
                        />
                        {
                          <span className='error'>
                            {translate(errors.buyer_email_id)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.BUYER_ADDRESS'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.BUYER_ADDRESS'
                            ) + ''
                          }
                          autoComplete='off'
                          maxLength={commanValidation.maxLengthAddress}
                          name='buyer_address'
                          value={buyerSellerDetails.buyer_address}
                          onChange={(e) => {
                            handleBuyerSellerChange(e);
                          }}
                          required
                        />
                        {
                          <span className='error'>
                            {translate(errors.buyer_address)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_NAME'
                          )}
                        </Form.Label>
                        <CreatableSelect
                          isClearable
                          value={selectSellerName}
                          onChange={handleSellerName}
                          options={valueChainData}
                          placeholder={translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_NAME'
                          )}
                          isDisabled={
                            basic.role === onBehalfRole.SELLER ? true : false
                          }
                        />
                        <Form.Control
                          tabIndex={-1}
                          type='text'
                          autoComplete='off'
                          value={sellerName}
                          required
                          style={{ opacity: 0, height: 0, display: 'none' }}
                          disabled={
                            basic.role === onBehalfRole.SELLER ? true : false
                          }
                          onChange={() => {}}
                        />
                        {
                          <span className='error'>
                            {translate(errors.sellerName)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_CATEGORY'
                          )}
                        </Form.Label>
                        <Form.Select
                          className='form-control'
                          name='seller_category'
                          value={buyerSellerDetails.seller_category}
                          onChange={(e) => {
                            handleBuyerSellerChange(e);
                          }}
                          required
                          disabled={
                            basic.role === onBehalfRole.SELLER ? true : false
                          }
                        >
                          <option value={''}>
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_SELLER_CATEGORY'
                            )}
                          </option>
                          {categoryData?.length > 0 &&
                            categoryData?.map((item: any, index: number) => {
                              return (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        {
                          <span className='error'>
                            {translate(errors.seller_category)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_CONTACT_NUMBER'
                          )}
                        </Form.Label>
                        <input
                          type='number'
                          className='form-control'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_CONTACT_NUMBER'
                            ) + ''
                          }
                          autoComplete='off'
                          name='seller_mobile_no'
                          pattern={commanValidation.numberPattern}
                          max={commanValidation.mobileMaxLength}
                          min={commanValidation.mobileMinLength}
                          value={buyerSellerDetails.seller_mobile_no}
                          onChange={(e) => {
                            handleBuyerSellerChange(e);
                          }}
                        />
                        {
                          <span className='error'>
                            {translate(errors.seller_mobile_no)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_EMAIL'
                          )}
                        </Form.Label>
                        <input
                          type='email'
                          className='form-control'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_EMAIL'
                            ) + ''
                          }
                          autoComplete='off'
                          maxLength={commanValidation.maxLength}
                          minLength={commanValidation.minLengthEmail}
                          name='seller_email_id'
                          value={buyerSellerDetails.seller_email_id}
                          onChange={(e) => {
                            handleBuyerSellerChange(e);
                          }}
                        />
                        {
                          <span className='error'>
                            {translate(errors.seller_email_id)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_ADDRESS'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_ADDRESS'
                            ) + ''
                          }
                          autoComplete='off'
                          name='seller_address'
                          maxLength={commanValidation.maxLengthAddress}
                          value={buyerSellerDetails.seller_address}
                          onChange={(e) => {
                            handleBuyerSellerChange(e);
                          }}
                          required
                        />
                        {
                          <span className='error'>
                            {translate(errors.seller_address)}
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate(
                    'TRANSACTION_REGISTRY_PAGE.TITLES.PAYMENT_DETAILS'
                  )}
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className={`form-group`}>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE_NUMBER'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE_NUMBER'
                            ) + ''
                          }
                          autoComplete='off'
                          name='payment_reference'
                          value={buyerSellerDetails.payment_reference}
                          onChange={(e) => {
                            handleBuyerSellerChange(e);
                          }}
                          maxLength={commanValidation.maxLengthReference}
                          // disabled={id ? true : false}
                          required
                        />
                        {
                          <span className='error'>
                            {translate(errors.payment_reference)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className={`form-group`}>
                        <Form.Label>
                          {translate('TRANSACTION_REGISTRY_PAGE.TITLES.AMOUNT')}
                        </Form.Label>
                        <input
                          type='number'
                          className='form-control'
                          name='payment_amount'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.AMOUNT'
                            ) + ''
                          }
                          value={buyerSellerDetails.payment_amount}
                          onChange={(e) => {
                            handleBuyerSellerChange(e);
                          }}
                          onFocus={disableScrollForTypeNumber}
                          pattern={commanValidation.numberPattern}
                          max={commanValidation.maxQty}
                          required
                          // disabled={id ? true : false}
                        />
                        {
                          <span className='error'>
                            {translate(errors.payment_amount)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className={`form-group date-input`}>
                        <Form.Label>
                          {translate('TRANSACTION_REGISTRY_PAGE.TITLES.DATE')}
                        </Form.Label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <DatePicker
                              selected={buyerSellerDetails.payment_date}
                              className='form-control'
                              placeholderText={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                ) + ''
                              }
                              dateFormat='dd/MM/yyyy'
                              onChange={handleBuyerSellerChangePaymentDate}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              required
                              // disabled={id ? true : false}
                            />
                            {
                              <span className='error'>
                                {/* {translate(errors.payment_date)} */}
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate(
                    'TRANSACTION_REGISTRY_PAGE.TITLES.INVOICE_DETAILS'
                  )}
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className={`form-group date-input required`}>
                        <Form.Label>
                          {translate('TRANSACTION_REGISTRY_PAGE.TITLES.DATE')}
                        </Form.Label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <DatePicker
                              selected={buyerSellerDetails.date}
                              className='form-control'
                              placeholderText={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                ) + ''
                              }
                              dateFormat='dd/MM/yyyy'
                              onChange={handleBuyerSellerChangeDate}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              required
                              // disabled={id ? true : false}
                            />
                            {
                              <span className='error'>
                                {translate(errors.date)}
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div
                        className={`form-group group-i-input ${
                          isInwardTransfer ? '' : 'required'
                        }`}
                      >
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.QUANTITY'
                          )}
                        </Form.Label>
                        <div className='input-group'>
                          <div className='input-left input-left-right'>
                            <input
                              type='number'
                              className='form-control'
                              name='quantity'
                              autoComplete='off'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.QUANTITY'
                                ) + ''
                              }
                              value={buyerSellerDetails.quantity}
                              onChange={(e) => {
                                handleBuyerSellerChange(e);
                              }}
                              onFocus={disableScrollForTypeNumber}
                              step={commanValidation.tonneMin}
                              min={
                                buyerSellerDetails.measurement_types ===
                                commanLabel.kgLabel
                                  ? commanValidation.kgMin
                                  : commanValidation.tonneMin
                              }
                              pattern={commanValidation.numberPattern}
                              max={commanValidation.maxQty}
                              required
                              // disabled={id ? true : false}
                            />
                            {
                              <span className='error'>
                                {translate(errors.quantity)}
                              </span>
                            }
                            <p className='upload-note'>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.MIN_QTY_ALLOWED'
                              )}{' '}
                            </p>
                          </div>
                          <div className='input-right input-left-right'>
                            <Form.Select
                              className='form-control'
                              name='measurement_types'
                              value={buyerSellerDetails.measurement_types}
                              onChange={(e) => {
                                handleBuyerSellerChange(e);
                              }}
                              // disabled={id ? true : false}
                            >
                              {measurementTypes?.map(
                                (item: any, index: number) => {
                                  return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.DOCUMENT'
                          )}
                        </Form.Label>
                        {buyerSellerDetails?.invoice && (
                          <a
                            href={buyerSellerDetails?.invoice}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='float-end'
                          >
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.PREVIEW'
                            )}
                          </a>
                        )}
                        <div className='upload-file-main'>
                          <i className='fa fa-upload' aria-hidden='true' />
                          <div className='attach-document'>
                            <div className='file-drop-area text-center'>
                              <span className='file-msg' />
                              {buyerSellerDetails.invoice_file_name && (
                                <p className='file-name'>
                                  {buyerSellerDetails.invoice_file_name}
                                </p>
                              )}
                              <input
                                className='file-input'
                                type='file'
                                onChange={handleSelectedFile}
                                name='invoice'
                                id='invoice'
                                accept='.jpg, .jpeg, .png, .pdf'
                                // disabled={id ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                        <p className='upload-note'>
                          {translate('ERRORS.INVALID.DOCUMENT')}{' '}
                        </p>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div
                        className={`form-group required ${
                          isInwardTransfer ? '' : 'required'
                        }`}
                      >
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                            ) + ''
                          }
                          autoComplete='off'
                          name='reference'
                          value={buyerSellerDetails.reference}
                          onChange={(e) => {
                            handleBuyerSellerChange(e);
                          }}
                          maxLength={commanValidation.maxLengthReference}
                          // disabled={id ? true : false}
                          required
                        />
                        {
                          <span className='error'>
                            {translate(errors.reference)}
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate(
                    'TRANSACTION_REGISTRY_PAGE.TITLES.LOADING_WEIGH_SLIP_DETAILS'
                  )}
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div
                        className={`form-group group-i-input ${
                          isInwardTransfer ? '' : 'required'
                        }`}
                      >
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.QUANTITY'
                          )}
                        </Form.Label>
                        <div className='input-group'>
                          <div className='input-left input-left-right'>
                            <input
                              type='number'
                              className='form-control'
                              autoComplete='off'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.QUANTITY'
                                ) + ''
                              }
                              name='quantity'
                              value={loadingSlipDetails.quantity}
                              onChange={(e) => {
                                handleLoadingSlipChange(e);
                              }}
                              onFocus={disableScrollForTypeNumber}
                              step={commanValidation.tonneMin}
                              min={
                                loadingSlipDetails.measurement_types ===
                                commanLabel.kgLabel
                                  ? commanValidation.kgMin
                                  : commanValidation.tonneMin
                              }
                              pattern={commanValidation.numberPattern}
                              max={commanValidation.maxQty}
                              // disabled={id ? true : false}
                              required
                            />
                            {
                              <span className='error'>
                                {translate(errors.loadingSlipDetails.quantity)}
                              </span>
                            }
                            <p className='upload-note'>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.MIN_QTY_ALLOWED'
                              )}
                            </p>
                          </div>
                          <div className='input-right input-left-right'>
                            <Form.Select
                              className='form-control'
                              name='measurement_types'
                              value={loadingSlipDetails.measurement_types}
                              // disabled={id ? true : false}
                              onChange={(e) => {
                                handleLoadingSlipChange(e);
                              }}
                            >
                              {measurementTypes?.map(
                                (item: any, index: number) => {
                                  return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div
                        className={`form-group ${
                          isInwardTransfer ? '' : 'required'
                        }`}
                      >
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                            ) + ''
                          }
                          name='reference'
                          value={loadingSlipDetails.reference}
                          onChange={(e) => {
                            handleLoadingSlipChange(e);
                          }}
                          maxLength={commanValidation.maxLengthReference}
                          // disabled={id ? true : false}
                          required
                        />
                        {
                          <span className='error'>
                            {translate(errors.loadingSlipDetails.reference)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div
                        className={`form-group date-input ${
                          isInwardTransfer ? '' : 'required'
                        }`}
                      >
                        <Form.Label>
                          {translate('TRANSACTION_REGISTRY_PAGE.TITLES.DATE')}
                        </Form.Label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <DatePicker
                              selected={loadingSlipDetails.date}
                              className='form-control'
                              placeholderText={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                ) + ''
                              }
                              dateFormat='dd/MM/yyyy'
                              onChange={handleLoadingSlipChangeDate}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              required
                              // disabled={id ? true : false}
                            />
                            {
                              <span className='error'>
                                {translate(errors.loadingSlipDetails.date)}
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.DOCUMENT'
                          )}
                        </Form.Label>
                        {loadingSlipDetails?.loading_weight_slip && (
                          <a
                            href={loadingSlipDetails?.loading_weight_slip}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='float-end'
                          >
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.PREVIEW'
                            )}
                          </a>
                        )}
                        <div className='upload-file-main'>
                          <i className='fa fa-upload' aria-hidden='true' />
                          <div className='attach-document'>
                            <div className='file-drop-area text-center'>
                              <span className='file-msg' />
                              {loadingSlipDetails.loading_weight_slip_file_name && (
                                <p className='file-name'>
                                  {
                                    loadingSlipDetails.loading_weight_slip_file_name
                                  }
                                </p>
                              )}
                              <Form.Control
                                className='file-input'
                                type='file'
                                onChange={handleSelectedFile}
                                name='loading_weight_slip'
                                id='loading_weight_slip'
                                // disabled={id ? true : false}
                                accept='.jpg, .jpeg, .png, .pdf'
                              />
                            </div>
                          </div>
                        </div>
                        <p className='upload-note'>
                          {translate('ERRORS.INVALID.DOCUMENT')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate(
                    'TRANSACTION_REGISTRY_PAGE.TITLES.UNLOADING_WEIGH_SLIP_DETAILS'
                  )}
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group required group-i-input'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.QUANTITY'
                          )}
                        </Form.Label>
                        <div className='input-group'>
                          <div className='input-left input-left-right'>
                            <input
                              type='number'
                              className='form-control'
                              autoComplete='off'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.QUANTITY'
                                ) + ''
                              }
                              name='quantity'
                              value={unLoadingSlipDetails.quantity}
                              step={commanValidation.tonneMin}
                              min={
                                unLoadingSlipDetails.measurement_types ===
                                commanLabel.kgLabel
                                  ? commanValidation.kgMin
                                  : commanValidation.tonneMin
                              }
                              pattern={commanValidation.numberPattern}
                              max={commanValidation.maxQty}
                              onChange={(e) => {
                                handlUnloadingSlipChange(e);
                              }}
                              onFocus={disableScrollForTypeNumber}
                              // disabled={id ? true : false}
                              required
                            />
                            {
                              <span className='error'>
                                {translate(
                                  errors.unLoadingSlipDetails.quantity
                                )}
                              </span>
                            }
                            <p className='upload-note'>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.MIN_QTY_ALLOWED'
                              )}
                            </p>
                          </div>
                          <div className='input-right input-left-right'>
                            <Form.Select
                              className='form-control'
                              name='measurement_types'
                              value={unLoadingSlipDetails.measurement_types}
                              onChange={(e) => {
                                handlUnloadingSlipChange(e);
                              }}
                              // disabled={id ? true : false}
                            >
                              {measurementTypes?.map(
                                (item: any, index: number) => {
                                  return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div
                        className={`form-group ${
                          isInwardTransfer ? '' : 'required'
                        }`}
                      >
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                            ) + ''
                          }
                          name='reference'
                          maxLength={commanValidation.maxLengthReference}
                          value={unLoadingSlipDetails.reference}
                          onChange={(e) => {
                            handlUnloadingSlipChange(e);
                          }}
                          // disabled={id ? true : false}
                          required
                        />
                        {
                          <span className='error'>
                            {translate(errors.unLoadingSlipDetails.reference)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div
                        className={`form-group date-input ${
                          isInwardTransfer ? '' : 'required'
                        }`}
                      >
                        <Form.Label>
                          {translate('TRANSACTION_REGISTRY_PAGE.TITLES.DATE')}
                        </Form.Label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <DatePicker
                              selected={unLoadingSlipDetails.date}
                              className='form-control'
                              placeholderText={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                                ) + ''
                              }
                              dateFormat='dd/MM/yyyy'
                              onChange={handleUnloadingSlipChangeDate}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              // disabled={id ? true : false}
                              required
                            />
                            {
                              <span className='error'>
                                {translate(errors.unLoadingSlipDetails.date)}
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.DOCUMENT'
                          )}
                        </Form.Label>
                        {unLoadingSlipDetails?.unloading_weight_slip && (
                          <a
                            href={unLoadingSlipDetails?.unloading_weight_slip}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='float-end'
                          >
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.PREVIEW'
                            )}
                          </a>
                        )}
                        <div className='upload-file-main'>
                          <i className='fa fa-upload' aria-hidden='true' />
                          <div className='attach-document'>
                            <div className='file-drop-area text-center'>
                              <span className='file-msg' />
                              {unLoadingSlipDetails.unloading_weight_slip_file_name && (
                                <p className='file-name'>
                                  {
                                    unLoadingSlipDetails.unloading_weight_slip_file_name
                                  }
                                </p>
                              )}
                              <Form.Control
                                className='file-input'
                                type='file'
                                onChange={handleSelectedFile}
                                name='unloading_weight_slip'
                                id='unloading_weight_slip'
                                // disabled={id ? true : false}
                                accept='.jpg, .jpeg, .png, .pdf'
                              />
                            </div>
                          </div>
                        </div>
                        <p className='upload-note'>
                          {translate('ERRORS.INVALID.DOCUMENT')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate(
                    'TRANSACTION_REGISTRY_PAGE.TITLES.EWAY_LORRY_RECEIPT'
                  )}
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group group-i-input'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.QUANTITY'
                          )}
                        </Form.Label>
                        <div className='input-group'>
                          <div className='input-left input-left-right'>
                            <input
                              type='number'
                              className='form-control'
                              autoComplete='off'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.QUANTITY'
                                ) + ''
                              }
                              name='quantity'
                              value={ewayLorryReceipt.quantity}
                              step={commanValidation.tonneMin}
                              min={
                                ewayLorryReceipt.measurement_types ===
                                commanLabel.kgLabel
                                  ? commanValidation.kgMin
                                  : commanValidation.tonneMin
                              }
                              pattern={commanValidation.numberPattern}
                              max={commanValidation.maxQty}
                              onChange={(e) => {
                                handleEwayChange(e);
                              }}
                              onFocus={disableScrollForTypeNumber}
                              // disabled={id ? true : false}
                            />
                            {
                              <span className='error'>
                                {translate(errors.ewayLorryReceipt.quantity)}
                              </span>
                            }
                            <p className='upload-note'>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.MIN_QTY_ALLOWED'
                              )}
                            </p>
                          </div>
                          <div className='input-right input-left-right'>
                            <Form.Select
                              className='form-control'
                              name='measurement_types'
                              value={ewayLorryReceipt.measurement_types}
                              onChange={(e) => {
                                handleEwayChange(e);
                              }}
                              // disabled={id ? true : false}
                            >
                              {measurementTypes?.map(
                                (item: any, index: number) => {
                                  return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                            ) + ''
                          }
                          name='reference'
                          maxLength={commanValidation.maxLengthReference}
                          value={ewayLorryReceipt.reference}
                          onChange={(e) => {
                            handleEwayChange(e);
                          }}
                          // disabled={id ? true : false}
                        />
                        {
                          <span className='error'>
                            {translate(errors.ewayLorryReceipt.reference)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group date-input'>
                        <Form.Label>
                          {translate('TRANSACTION_REGISTRY_PAGE.TITLES.DATE')}
                        </Form.Label>
                        <div className='date-item-picker'>
                          <div className='date-picker-main'>
                            <DatePicker
                              selected={ewayLorryReceipt.date}
                              className='form-control'
                              placeholderText={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                ) + ''
                              }
                              dateFormat='dd/MM/yyyy'
                              onChange={handleEwayChangeDate}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode='select'
                              // disabled={id ? true : false}
                            />
                            {
                              <span className='error'>
                                {translate(errors.ewayLorryReceipt.date)}
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.CARRIER_REGISTRATION'
                          )}
                        </Form.Label>
                        <input
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.CARRIER_REGISTRATION'
                            ) + ''
                          }
                          name='carrier_registration_no'
                          value={ewayLorryReceipt.carrier_registration_no}
                          onChange={(e) => {
                            handleEwayChange(e);
                          }}
                          maxLength={commanValidation.maxLengthReference}
                          // disabled={id ? true : false}
                        />
                        {
                          <span className='error'>
                            {translate(
                              errors.ewayLorryReceipt.carrier_registration_no
                            )}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.TRANSPORT_NAME'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.TRANSPORT_NAME'
                            ) + ''
                          }
                          name='transport_name'
                          maxLength={commanValidation.maxLength}
                          value={ewayLorryReceipt.transport_name}
                          onChange={(e) => {
                            handleEwayChange(e);
                          }}
                          // disabled={id ? true : false}
                        />
                        {
                          <span className='error'>
                            {translate(errors.ewayLorryReceipt.transport_name)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.TRANSPORT_ADDRESS'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.TRANSPORT_ADDRESS'
                            ) + ''
                          }
                          name='transport_address'
                          maxLength={commanValidation.maxLengthAddress}
                          value={ewayLorryReceipt.transport_address}
                          onChange={(e) => {
                            handleEwayChange(e);
                          }}
                          // disabled={id ? true : false}
                        />
                        {
                          <span className='error'>
                            {translate(
                              errors.ewayLorryReceipt.transport_address
                            )}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.DOCUMENT'
                          )}
                        </Form.Label>
                        {ewayLorryReceipt?.eway_or_lorry_reciept && (
                          <a
                            href={ewayLorryReceipt?.eway_or_lorry_reciept}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='float-end'
                          >
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.PREVIEW'
                            )}
                          </a>
                        )}
                        <div className='upload-file-main'>
                          <i className='fa fa-upload' aria-hidden='true' />
                          <div className='attach-document'>
                            <div className='file-drop-area text-center'>
                              <span className='file-msg' />
                              {ewayLorryReceipt.eway_or_lorry_reciept_file_name && (
                                <p className='file-name'>
                                  {
                                    ewayLorryReceipt.eway_or_lorry_reciept_file_name
                                  }
                                </p>
                              )}
                              <Form.Control
                                className='file-input'
                                type='file'
                                onChange={handleSelectedFile}
                                name='eway_or_lorry_reciept'
                                id='eway_or_lorry_reciept'
                                // disabled={id ? true : false}
                                accept='.jpg, .jpeg, .png, .pdf'
                              />
                            </div>
                          </div>
                        </div>
                        <p className='upload-note'>
                          {translate('ERRORS.INVALID.DOCUMENT')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate(
                    'TRANSACTION_REGISTRY_PAGE.TITLES.DEBIT_NOTE_BUYER'
                  )}
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-12 oneline-radio'>
                      <div
                        className={`form-group ${
                          isInwardTransfer ? '' : 'required'
                        }`}
                      >
                        <Form.Label className='label-single'>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.WAS_QUANTITY_RETURNED'
                          )}
                        </Form.Label>
                        <div className='radio-buttons no-req'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='any_quanty_return'
                              id='yes-icon'
                              value={'true'}
                              checked={debitNote.any_quanty_return === 'true'}
                              onChange={(e) => {
                                handleDebitNoteChange(e);
                              }}
                              // disabled={id ? true : false}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='yes-icon'
                            >
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.YES'
                              )}
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='any_quanty_return'
                              id='no-icon'
                              value={'false'}
                              checked={debitNote.any_quanty_return === 'false'}
                              onChange={(e) => {
                                handleDebitNoteChange(e);
                              }}
                              // disabled={id ? true : false}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='no-icon'
                            >
                              {translate('TRANSACTION_REGISTRY_PAGE.TITLES.NO')}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div
                        className={`form-group group-i-input ${
                          isInwardTransfer ? '' : 'required'
                        }`}
                      >
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.QUANTITY'
                          )}
                        </Form.Label>
                        <div className='input-group'>
                          <div className='input-left input-left-right'>
                            <input
                              type='number'
                              className='form-control'
                              autoComplete='off'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.QUANTITY'
                                ) + ''
                              }
                              name='quantity_return'
                              value={debitNote.quantity_return}
                              step={commanValidation.tonneMin}
                              min={
                                debitNote.measurement_types ===
                                commanLabel.kgLabel
                                  ? commanValidation.kgMin
                                  : commanValidation.tonneMin
                              }
                              pattern={commanValidation.numberPattern}
                              max={commanValidation.maxQty}
                              onChange={(e) => {
                                handleDebitNoteChange(e);
                              }}
                              onFocus={disableScrollForTypeNumber}
                              disabled={
                                debitNote.any_quanty_return === 'false'
                                  ? // || id
                                    true
                                  : false
                              }
                              required
                            />
                            {
                              <span className='error'>
                                {translate(errors.debitNote.quantity_return)}
                              </span>
                            }
                            <p className='upload-note'>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.MIN_QTY_ALLOWED'
                              )}
                            </p>
                          </div>
                          <div className='input-right input-left-right'>
                            <Form.Select
                              className='form-control'
                              name='measurement_types'
                              value={
                                debitNote.any_quanty_return === 'true'
                                  ? debitNote.measurement_types
                                  : ''
                              }
                              onChange={(e) => {
                                handleDebitNoteChange(e);
                              }}
                              disabled={
                                debitNote.any_quanty_return === 'false'
                                  ? // || id
                                    true
                                  : false
                              }
                              required
                            >
                              {measurementTypes?.map(
                                (item: any, index: number) => {
                                  return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.DOCUMENT'
                          )}
                        </Form.Label>
                        {debitNote?.debit_note && (
                          <a
                            href={debitNote?.debit_note}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='float-end'
                          >
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.PREVIEW'
                            )}
                          </a>
                        )}
                        <div className='upload-file-main'>
                          <i className='fa fa-upload' aria-hidden='true' />
                          <div className='attach-document'>
                            <div className='file-drop-area text-center'>
                              <span className='file-msg' />
                              {debitNote.debit_note_file_name && (
                                <p className='file-name'>
                                  {debitNote.debit_note_file_name}
                                </p>
                              )}
                              <Form.Control
                                className='file-input'
                                type='file'
                                onChange={handleSelectedFile}
                                name='debit_note'
                                id='debit_note'
                                // disabled={id ? true : false}
                                accept='.jpg, .jpeg, .png, .pdf'
                              />
                            </div>
                          </div>
                        </div>
                        <p className='upload-note'>
                          {translate('ERRORS.INVALID.DOCUMENT')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate(
                    'TRANSACTION_REGISTRY_PAGE.TITLES.GOODS_RECEIVED_NOTE'
                  )}{' '}
                  <span>
                    {' '}
                    {translate(
                      'TRANSACTION_REGISTRY_PAGE.TITLES.APPLICABLE_FOR'
                    )}
                  </span>
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                            ) + ''
                          }
                          name='reference'
                          value={goodNote.reference}
                          maxLength={commanValidation.maxLengthReference}
                          onChange={(e) => {
                            handleGoodNoteChange(e);
                          }}
                          // disabled={id ? true : false}
                          required
                        />
                        {
                          <span className='error'>
                            {translate(errors.goodNote.reference)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.DOCUMENT'
                          )}
                        </Form.Label>
                        {goodNote?.GRN && (
                          <a
                            href={goodNote?.GRN}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='float-end'
                          >
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.PREVIEW'
                            )}
                          </a>
                        )}
                        <div className='upload-file-main'>
                          <i className='fa fa-upload' aria-hidden='true' />
                          <div className='attach-document'>
                            <div className='file-drop-area text-center'>
                              <span className='file-msg' />
                              {goodNote.GRN__file_name && (
                                <p className='file-name'>
                                  {goodNote.GRN__file_name}
                                </p>
                              )}
                              <Form.Control
                                className='file-input'
                                type='file'
                                onChange={handleSelectedFile}
                                name='GRN'
                                id='GRN'
                                // disabled={id ? true : false}
                                accept='.jpg, .jpeg, .png, .pdf'
                              />
                            </div>
                          </div>
                        </div>
                        <p className='upload-note'>
                          {translate('ERRORS.INVALID.DOCUMENT')}
                        </p>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group group-i-input'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.QUANTITY'
                          )}
                        </Form.Label>
                        <div className='input-group'>
                          <div className='input-left input-left-right'>
                            <input
                              type='number'
                              className='form-control'
                              autoComplete='off'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.QUANTITY'
                                ) + ''
                              }
                              name='quantity'
                              value={goodNote.quantity}
                              step={commanValidation.tonneMin}
                              min={
                                goodNote.measurement_types ===
                                commanLabel.kgLabel
                                  ? commanValidation.kgMin
                                  : commanValidation.tonneMin
                              }
                              pattern={commanValidation.numberPattern}
                              max={commanValidation.maxQty}
                              onChange={(e) => {
                                handleGoodNoteChange(e);
                              }}
                              onFocus={disableScrollForTypeNumber}
                              // disabled={id ? true : false}
                            />
                            {
                              <span className='error'>
                                {translate(errors.goodNote.quantity)}
                              </span>
                            }
                            <p className='upload-note'>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.MIN_QTY_ALLOWED'
                              )}
                            </p>
                          </div>
                          <div className='input-right input-left-right'>
                            <Form.Select
                              className='form-control'
                              name='measurement_types'
                              value={goodNote.measurement_types}
                              onChange={(e) => {
                                handleGoodNoteChange(e);
                              }}
                              // disabled={id ? true : false}
                            >
                              {measurementTypes?.map(
                                (item: any, index: number) => {
                                  return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                }
                              )}
                            </Form.Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Commenting this becoz we will implement this on next release */}
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate(
                    'TRANSACTION_REGISTRY_PAGE.TITLES.GOOD_TRANSFER_DETAILS'
                  )}
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.MATERIAL_TRANSFERRED'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.MATERIAL_TRANSFERRED'
                            ) + ''
                          }
                          name='material_transferred'
                          value={goodDetails?.material_transferred}
                          onChange={(e) => {
                            handleGoodDetails(e);
                          }}
                        />
                        {
                          <span className='error'>
                            {translate(errors.goodDetails.material_transferred)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.MATERIAL_FORM'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.MATERIAL_FORM'
                            ) + ''
                          }
                          name='material_form'
                          value={goodDetails?.material_form}
                          onChange={(e) => {
                            handleGoodDetails(e);
                          }}
                        />
                        {
                          <span className='error'>
                            {translate(errors.goodDetails.material_form)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.PACKAGING_FORM'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.PACKAGING_FORM'
                            ) + ''
                          }
                          name='packaging_form'
                          value={goodDetails?.packaging_form}
                          onChange={(e) => {
                            handleGoodDetails(e);
                          }}
                        />
                        {
                          <span className='error'>
                            {translate(errors.goodDetails.packaging_form)}
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Commenting this becoz we will implement this on next release */}
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate(
                    'TRANSACTION_REGISTRY_PAGE.TITLES.OUTPUT_MATERIAL'
                  )}
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.MATERIAL_DESCRIPTION'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.MATERIAL_DESCRIPTION'
                            ) + ''
                          }
                          name='material_description'
                          value={outputDetails?.material_description}
                          onChange={(e) => {
                            handleOutputDetails(e);
                          }}
                        />
                        {
                          <span className='error'>
                            {translate(
                              errors.outputDetails.material_description
                            )}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.MATERIAL_QUANTITY'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.MATERIAL_QUANTITY'
                            ) + ''
                          }
                          name='material_quantity'
                          value={outputDetails?.material_quantity}
                          onChange={(e) => {
                            handleOutputDetails(e);
                          }}
                        />
                        {
                          <span className='error'>
                            {translate(errors.outputDetails.material_quantity)}
                          </span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate(
                    'TRANSACTION_REGISTRY_PAGE.TITLES.RECYCLING_CERTIFICATE'
                  )}
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                            ) + ''
                          }
                          name='reference'
                          value={recyclingCertificate?.reference}
                          maxLength={commanValidation.maxLengthReference}
                          onChange={(e) => {
                            handleRecyclingCertificateChange(e);
                          }}
                          // disabled={id ? true : false}
                          required
                        />
                        {
                          <span className='error'>
                            {translate(errors.recyclingCertificate.reference)}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.DOCUMENT'
                          )}
                        </Form.Label>
                        {recyclingCertificate?.recycling_certificate_document && (
                          <a
                            href={
                              recyclingCertificate?.recycling_certificate_document
                            }
                            target='_blank'
                            rel='noopener noreferrer'
                            className='float-end'
                          >
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.PREVIEW'
                            )}
                          </a>
                        )}
                        <div className='upload-file-main'>
                          <i className='fa fa-upload' area-hidden='true' />
                          <div className='attach-document'>
                            <div className='file-drop-area text-center'>
                              <span className='file-msg' />
                              {recyclingCertificate?.recycling_certificate_document_file_name && (
                                <p className='file-name'>
                                  {
                                    recyclingCertificate.recycling_certificate_document_file_name
                                  }
                                </p>
                              )}
                              <Form.Control
                                className='file-input'
                                type='file'
                                onChange={handleSelectedFile}
                                name='recycling_certificate'
                                id='recycling_certificate'
                                // disabled={id ? true : false}
                                accept='.jpg, .jpeg, .png, .pdf'
                              />
                            </div>
                          </div>
                        </div>
                        <p className='upload-note'>
                          {translate('ERRORS.INVALID.DOCUMENT')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-box'>
                <div className='panel-title icon-btn-title'>
                  {translate(
                    'TRANSACTION_REGISTRY_PAGE.TITLES.ADDITIONAL_DOCUMENTS'
                  )}
                  <button
                    type='button'
                    className='btn-add'
                    disabled={
                      // id ||
                      additionalDocuments.length >= 6 ? true : false
                    }
                    onClick={() => {
                      if (additionalDocuments.length < 6) {
                        additionalDocuments.push(additionalDocumentInfo);
                        setAdditionalDocuments([...additionalDocuments]);
                      }
                    }}
                  >
                    {' '}
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/round-plus.svg`}
                      alt='Add'
                    />
                  </button>
                </div>
                <div className='combine-panel-border'>
                  {additionalDocuments &&
                    additionalDocuments?.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className='panel-data panel-data-border'
                        >
                          {index > 0 && (
                            <button
                              type='button'
                              className='delete-button-icon'
                              disabled={
                                // id ||
                                additionalDocuments.length <= 1 ? true : false
                              }
                              onClick={() => {
                                if (additionalDocuments.length > 0) {
                                  additionalDocuments.splice(index, 1);
                                  setAdditionalDocuments([
                                    ...additionalDocuments,
                                  ]);
                                  if (isSubmitted) {
                                    validateForm({
                                      ...basic,
                                      ...buyerSellerDetails,
                                      loadingSlipDetails,
                                      unLoadingSlipDetails,
                                      additionalDocuments: [
                                        ...additionalDocuments,
                                      ],
                                      ewayLorryReceipt,
                                      debitNote,
                                      goodNote,
                                      previousDetails,
                                      nextDetails,
                                      otherDetails,
                                      onBehalfName,
                                      materialName,
                                      buyerName,
                                      sellerName,
                                      goodDetails,
                                      outputDetails,
                                    });
                                  }
                                }
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/delete-tab-icon.svg`}
                                alt='Delete'
                              />
                            </button>
                          )}
                          <div className='panel-border-in'>
                            <div className='row'>
                              <div className='col-md-6'>
                                <div className='form-group'>
                                  <Form.Label>
                                    {translate(
                                      'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                                    )}
                                  </Form.Label>
                                  <Form.Control
                                    type='text'
                                    className='form-control'
                                    autoComplete='off'
                                    placeholder={
                                      translate(
                                        'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE'
                                      ) + ''
                                    }
                                    name='reference'
                                    maxLength={
                                      commanValidation.maxLengthReference
                                    }
                                    value={item.reference}
                                    onChange={(e) => {
                                      handleAdditionalDocumentInfoChange(
                                        e,
                                        index
                                      );
                                    }}
                                    // disabled={id ? true : false}
                                    required
                                  />
                                  {
                                    <span className='error'>
                                      {translate(
                                        errors.additionalDocuments[index]
                                          ?.reference
                                      )}
                                    </span>
                                  }
                                </div>
                              </div>
                              <div className='col-md-6'>
                                <div
                                  className={`form-group ${
                                    item.label ? 'required' : ''
                                  }`}
                                >
                                  <Form.Label>
                                    {translate(
                                      'TRANSACTION_REGISTRY_PAGE.TITLES.DOCUMENT'
                                    )}
                                  </Form.Label>
                                  {item.document && (
                                    <a
                                      href={item.document}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className='float-end'
                                    >
                                      {translate(
                                        'TRANSACTION_REGISTRY_PAGE.TITLES.PREVIEW'
                                      )}
                                    </a>
                                  )}
                                  <div className='upload-file-main'>
                                    <i
                                      className='fa fa-upload'
                                      aria-hidden='true'
                                    />
                                    <div className='attach-document'>
                                      <div className='file-drop-area text-center'>
                                        <span className='file-msg' />
                                        {item.document_file_name && (
                                          <p className='file-name'>
                                            {item.document_file_name}
                                          </p>
                                        )}
                                        <Form.Control
                                          className='file-input'
                                          type='file'
                                          onChange={(e: any) => {
                                            const name =
                                              e?.target?.files?.[0]?.name;
                                            const split = name?.split('.');
                                            const newName = item?.label
                                              ? item?.label +
                                                '.' +
                                                split?.[split?.length - 1]
                                              : '';
                                            handleSelectedFile(
                                              e,
                                              index,
                                              newName
                                            );
                                          }}
                                          name='additional_documents'
                                          id='additional_documents'
                                          // disabled={id ? true : false}
                                          accept='.jpg, .jpeg, .png, .pdf'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {
                                    <span className='error'>
                                      {translate(
                                        errors.additionalDocuments[index]
                                          ?.document
                                      )}
                                    </span>
                                  }
                                  <p className='upload-note'>
                                    {translate('ERRORS.INVALID.DOCUMENT')}
                                  </p>
                                </div>
                              </div>
                              <div className='col-md-12'>
                                <div
                                  className={`form-group ${
                                    item.document ? 'required' : ''
                                  }`}
                                >
                                  <Form.Label>
                                    {translate(
                                      'TRANSACTION_REGISTRY_PAGE.TITLES.LABEL'
                                    )}
                                  </Form.Label>
                                  <Form.Control
                                    type='text'
                                    className='form-control'
                                    autoComplete='off'
                                    placeholder={
                                      translate(
                                        'TRANSACTION_REGISTRY_PAGE.TITLES.LABEL'
                                      ) + ''
                                    }
                                    name='label'
                                    maxLength={commanValidation.maxLength}
                                    value={item.label}
                                    onChange={(e) => {
                                      handleAdditionalDocumentInfoChange(
                                        e,
                                        index
                                      );
                                    }}
                                    // disabled={id ? true : false}
                                    required
                                  />
                                  {
                                    <span className='error'>
                                      {translate(
                                        errors.additionalDocuments[index]?.label
                                      )}
                                    </span>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className='panel-box'>
                <div className='panel-title'>
                  {translate('TRANSACTION_REGISTRY_PAGE.TITLES.OTHER_DETAILS')}
                </div>
                <div className='panel-data'>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE_NUMBER_LINK'
                          )}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control'
                          autoComplete='off'
                          placeholder={
                            translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.REFERENCE_NUMBER_LINK'
                            ) + ''
                          }
                          name='po_referece_number_or_link'
                          maxLength={commanValidation.maxLengthPoReference}
                          value={otherDetails?.po_referece_number_or_link}
                          onChange={(e) => {
                            handleOtherChange(e);
                          }}
                          // disabled={id ? true : false}
                        />
                        {
                          <span className='error'>
                            {translate(
                              errors.otherDetails.po_referece_number_or_link
                            )}
                          </span>
                        }
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.BUYER_LOCATION'
                          )}
                        </Form.Label>
                        <div
                          className='location-input'
                          onClick={(e) => setIsBuyerLocation(true)}
                        >
                          <div className='icon'>
                            <i className='fa-solid fa-up-right-from-square'></i>
                          </div>
                          <Form.Control
                            type='text'
                            className='form-control'
                            autoComplete='off'
                            placeholder={
                              translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.BUYER_LOCATION'
                              ) + ''
                            }
                            name='buyer_location'
                            value={otherDetails?.buyer_main_address}
                            // disabled={id ? true : false}
                            style={{ paddingRight: '30px' }}
                            required
                            onChange={() => {}}
                          />
                          {
                            <span className='error'>
                              {translate(
                                errors.otherDetails.buyer_main_address
                              )}
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='form-group required'>
                        <Form.Label>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_LOCATION'
                          )}
                        </Form.Label>
                        <div
                          className='location-input'
                          onClick={(e) => setIsSellerLocation(true)}
                        >
                          <div className='icon'>
                            <i className='fa-solid fa-up-right-from-square'></i>
                          </div>
                          <Form.Control
                            type='text'
                            className='form-control'
                            autoComplete='off'
                            placeholder={
                              translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_LOCATION'
                              ) + ''
                            }
                            name='seller_location'
                            value={otherDetails?.seller_main_address}
                            // disabled={id ? true : false}
                            style={{ paddingRight: '30px' }}
                            required
                            onChange={() => {}}
                          />
                          {
                            <span className='error'>
                              {translate(
                                errors.otherDetails.seller_main_address
                              )}
                            </span>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='panel-box no-bottom-space inner-border-box'>
                <div className='panel-box'>
                  <div className='panel-title'>
                    {translate(
                      'TRANSACTION_REGISTRY_PAGE.TITLES.NEXT_PREVIOUS_STAGE'
                    )}
                  </div>
                  <div className='panel-data'>
                    <div className='col-md-12'>
                      <div className='checkbox-in-data'>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='next-item'
                            value={nextDetails.isNext}
                            checked={
                              nextDetails.isNext === 'true' ? true : false
                            }
                            onChange={(e) => {
                              setNextDetails({
                                ...nextDetails,
                                isNext:
                                  nextDetails.isNext === 'false'
                                    ? 'true'
                                    : 'false',
                              });
                            }}
                            // disabled={id ? true : false}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='next-item'
                          >
                            {translate('TRANSACTION_REGISTRY_PAGE.TITLES.NEXT')}
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='prev-item'
                            value={previousDetails.isPrev}
                            checked={
                              previousDetails.isPrev === 'true' ? true : false
                            }
                            onChange={(e) => {
                              setPreviousDetails({
                                ...previousDetails,
                                isPrev:
                                  previousDetails.isPrev === 'false'
                                    ? 'true'
                                    : 'false',
                              });
                            }}
                            // disabled={id ? true : false}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='prev-item'
                          >
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.PREVIOUS'
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    {nextDetails.isNext === 'true' && (
                      <div className='row border-item'>
                        <div className='row-border-title'>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.NEXT_STAGE_TRANSACTION_INFO'
                          )}
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <Form.Label>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_BUYER_NAME'
                              )}
                            </Form.Label>
                            <Form.Control
                              type='text'
                              className='form-control'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_BUYER_NAME'
                                ) + ''
                              }
                              name='seller_buyer_name'
                              maxLength={commanValidation.maxLength}
                              value={nextDetails.seller_buyer_name}
                              // disabled={id ? true : false}
                              onChange={(e) => {
                                handleNextChange(e);
                              }}
                            />
                            {
                              <span className='error'>
                                {translate(
                                  errors.nextDetails.seller_buyer_name
                                )}
                              </span>
                            }
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <Form.Label>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.PO_SO_REFERENCE_NUMBER'
                              )}
                            </Form.Label>
                            <Form.Control
                              type='text'
                              className='form-control'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.PO_SO_REFERENCE_NUMBER'
                                ) + ''
                              }
                              name='PO_SO_reference_number'
                              value={nextDetails.PO_SO_reference_number}
                              // disabled={id ? true : false}
                              maxLength={commanValidation.maxLengthReference}
                              onChange={(e) => {
                                handleNextChange(e);
                              }}
                            />
                            {
                              <span className='error'>
                                {translate(
                                  errors.nextDetails.PO_SO_reference_number
                                )}
                              </span>
                            }
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <Form.Label>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_INVOICE_REFERENCE'
                              )}
                            </Form.Label>
                            <Form.Control
                              type='text'
                              className='form-control'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_INVOICE_REFERENCE'
                                ) + ''
                              }
                              name='seller_invoice_reference'
                              value={nextDetails.seller_invoice_reference}
                              // disabled={id ? true : false}
                              maxLength={commanValidation.maxLengthReference}
                              onChange={(e) => {
                                handleNextChange(e);
                              }}
                            />
                            {
                              <span className='error'>
                                {translate(
                                  errors.nextDetails.seller_invoice_reference
                                )}
                              </span>
                            }
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group date-input'>
                            <Form.Label>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_INVOICE_DATE'
                              )}
                            </Form.Label>
                            <div className='date-item-picker'>
                              <div className='date-picker-main'>
                                <DatePicker
                                  selected={nextDetails.seller_invoice_date}
                                  className='form-control'
                                  placeholderText={
                                    translate(
                                      'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                    ) + ''
                                  }
                                  dateFormat='dd/MM/yyyy'
                                  onChange={handleNextChangeDate}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode='select'
                                  required
                                  // disabled={id ? true : false}
                                />
                                {
                                  <span className='error'>
                                    {translate(
                                      errors.nextDetails.seller_invoice_date
                                    )}
                                  </span>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <Form.Label>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.WASTE_TYPE'
                              )}
                            </Form.Label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.WASTE_TYPE'
                                ) + ''
                              }
                              name='waste_type'
                              value={nextDetails.waste_type}
                              // disabled={id ? true : false}
                              maxLength={commanValidation.maxLength}
                              onChange={(e) => {
                                handleNextChange(e);
                              }}
                            />
                            {
                              <span className='error'>
                                {translate(errors.nextDetails.waste_type)}
                              </span>
                            }
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <Form.Label>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.UNLOADING_QUANTITY'
                              )}
                            </Form.Label>
                            <input
                              type='number'
                              className='form-control'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.UNLOADING_QUANTITY'
                                ) + ''
                              }
                              name='unloading_quantity'
                              value={nextDetails.unloading_quantity}
                              // disabled={id ? true : false}
                              min={commanValidation.kgMin}
                              max={commanValidation.maxQty}
                              pattern={commanValidation.numberPattern}
                              onChange={(e) => {
                                handleNextChange(e);
                              }}
                            />
                            <p className='upload-note'>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.MIN_QTY_ALLOWED'
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {previousDetails.isPrev === 'true' && (
                      <div className='row border-item'>
                        <div className='row-border-title'>
                          {translate(
                            'TRANSACTION_REGISTRY_PAGE.TITLES.PREVIOUS_STAGE_TRANSACTION_INFO'
                          )}
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <Form.Label>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_BUYER_NAME'
                              )}
                            </Form.Label>
                            <Form.Control
                              type='text'
                              className='form-control'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_BUYER_NAME'
                                ) + ''
                              }
                              name='seller_buyer_name'
                              value={previousDetails.seller_buyer_name}
                              maxLength={commanValidation.maxLength}
                              // disabled={id ? true : false}
                              onChange={(e) => {
                                handlePreviousChange(e);
                              }}
                            />
                            {
                              <span className='error'>
                                {translate(
                                  errors.previousDetails.seller_buyer_name
                                )}
                              </span>
                            }
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <Form.Label>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.PO_SO_REFERENCE_NUMBER'
                              )}
                            </Form.Label>
                            <Form.Control
                              type='text'
                              className='form-control'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.PO_SO_REFERENCE_NUMBER'
                                ) + ''
                              }
                              name='PO_SO_reference_number'
                              value={previousDetails.PO_SO_reference_number}
                              // disabled={id ? true : false}
                              maxLength={commanValidation.maxLengthReference}
                              onChange={(e) => {
                                handlePreviousChange(e);
                              }}
                            />
                            {
                              <span className='error'>
                                {translate(
                                  errors.previousDetails.PO_SO_reference_number
                                )}
                              </span>
                            }
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <Form.Label>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_INVOICE_REFERENCE'
                              )}
                            </Form.Label>
                            <Form.Control
                              type='text'
                              className='form-control'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_INVOICE_REFERENCE'
                                ) + ''
                              }
                              name='seller_invoice_reference'
                              value={previousDetails.seller_invoice_reference}
                              // disabled={id ? true : false}
                              maxLength={commanValidation.maxLengthReference}
                              onChange={(e) => {
                                handlePreviousChange(e);
                              }}
                            />
                            {
                              <span className='error'>
                                {translate(
                                  errors.previousDetails
                                    .seller_invoice_reference
                                )}
                              </span>
                            }
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group date-input'>
                            <Form.Label>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.SELLER_INVOICE_DATE'
                              )}
                            </Form.Label>
                            <div className='date-item-picker'>
                              <div className='date-picker-main'>
                                <DatePicker
                                  selected={previousDetails.seller_invoice_date}
                                  className='form-control'
                                  placeholderText={
                                    translate(
                                      'TRANSACTION_REGISTRY_PAGE.TITLES.SELECT_DATE'
                                    ) + ''
                                  }
                                  dateFormat='dd/MM/yyyy'
                                  onChange={handlePreviousChangeDate}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode='select'
                                  required
                                  // disabled={id ? true : false}
                                />
                                {
                                  <span className='error'>
                                    {translate(
                                      errors.previousDetails.seller_invoice_date
                                    )}
                                  </span>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <Form.Label>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.WASTE_TYPE'
                              )}
                            </Form.Label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.WASTE_TYPE'
                                ) + ''
                              }
                              name='waste_type'
                              value={previousDetails.waste_type}
                              // disabled={id ? true : false}
                              maxLength={commanValidation.maxLength}
                              onChange={(e) => {
                                handlePreviousChange(e);
                              }}
                            />
                            {
                              <span className='error'>
                                {translate(errors.previousDetails.waste_type)}
                              </span>
                            }
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <Form.Label>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.UNLOADING_QUANTITY'
                              )}
                            </Form.Label>
                            <input
                              type='number'
                              className='form-control'
                              placeholder={
                                translate(
                                  'TRANSACTION_REGISTRY_PAGE.TITLES.UNLOADING_QUANTITY'
                                ) + ''
                              }
                              name='unloading_quantity'
                              value={previousDetails.unloading_quantity}
                              min={commanValidation.kgMin}
                              max={commanValidation.maxQty}
                              pattern={commanValidation.numberPattern}
                              // disabled={id ? true : false}
                              onChange={(e) => {
                                handlePreviousChange(e);
                              }}
                            />
                            {
                              <span className='error'>
                                {translate(
                                  errors.previousDetails.unloading_quantity
                                )}
                              </span>
                            }
                            <p className='upload-note'>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.MIN_QTY_ALLOWED'
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='declaration-data'>
                          <div className='title'>
                            {translate(
                              'TRANSACTION_REGISTRY_PAGE.TITLES.DECLARATIONS'
                            )}
                          </div>
                          <ul>
                            <li>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.LABEL1'
                              )}
                            </li>
                            <li>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.LABEL2'
                              )}
                            </li>
                            <li>
                              {translate(
                                'TRANSACTION_REGISTRY_PAGE.TITLES.LABEL3'
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='form-submit-buttons'>
                <Button
                  className='btn btn-primary btn-outline-primary btn-md'
                  type='button'
                  onClick={(e) => {
                    e.preventDefault();
                    setDiscardPopup(true);
                  }}
                >
                  {translate('BUTTONS.DISCARD')}
                </Button>
                <Button
                  className='btn btn-primary btn-md'
                  type='button'
                  onClick={() => {
                    setIsSubmited(true);
                    validateForm(
                      {
                        ...basic,
                        ...buyerSellerDetails,
                        loadingSlipDetails,
                        unLoadingSlipDetails,
                        additionalDocuments,
                        ewayLorryReceipt,
                        debitNote,
                        goodNote,
                        previousDetails,
                        nextDetails,
                        otherDetails,
                        onBehalfName,
                        materialName,
                        buyerName,
                        sellerName,
                        isInwardTransfer,
                        recyclingCertificate,
                        goodDetails,
                        outputDetails,
                      },
                      true
                    );
                  }}
                >
                  {id
                    ? translate('BUTTONS.UPDATE')
                    : translate('BUTTONS.SUBMIT')}
                </Button>
              </div>
            </div>
          </Form>
          {(isBuyerLocation || isSellerLocation) && !id && (
            <LocationPicker
              title='Select Location'
              markerLocation={isBuyerLocation ? buyerLocation : sellerLocation}
              setMarkerLocation={
                isBuyerLocation ? setBuyerLocation : setSellerLocation
              }
              locationText={
                isBuyerLocation ? buyerLocationText : sellerLocationText
              }
              setLocationText={(location: any) => {
                isBuyerLocation
                  ? setBuyerLocationText(location)
                  : setSellerLocationText(location);

                // For validation of Location we have added this below code
                if (isBuyerLocation && isSubmitted) {
                  validateForm({
                    ...basic,
                    ...buyerSellerDetails,
                    otherDetails: {
                      ...otherDetails,
                      buyer_main_address: location,
                    },
                    loadingSlipDetails,
                    unLoadingSlipDetails,
                    ewayLorryReceipt,
                    debitNote,
                    goodNote,
                    previousDetails,
                    nextDetails,
                    onBehalfName,
                    materialName,
                    buyerName,
                    additionalDocuments,
                    sellerName,
                    isInwardTransfer,
                    recyclingCertificate,
                    goodDetails,
                    outputDetails,
                  });
                } else if (!isBuyerLocation && isSubmitted) {
                  validateForm({
                    ...basic,
                    ...buyerSellerDetails,
                    otherDetails: {
                      ...otherDetails,
                      seller_main_address: location,
                    },
                    loadingSlipDetails,
                    unLoadingSlipDetails,
                    ewayLorryReceipt,
                    debitNote,
                    goodNote,
                    previousDetails,
                    nextDetails,
                    onBehalfName,
                    materialName,
                    buyerName,
                    additionalDocuments,
                    sellerName,
                    isInwardTransfer,
                    recyclingCertificate,
                    goodDetails,
                    outputDetails,
                  });
                }
              }}
              show={isBuyerLocation || isSellerLocation}
              setShow={
                isBuyerLocation ? setIsBuyerLocation : setIsSellerLocation
              }
            />
          )}
          {discardPopup && (
            <ConfirmationPopup
              title={translate('BUTTONS.DISCARD')}
              message={translate(
                'TRANSACTION_REGISTRY_PAGE.TITLES.DISCARD_MESSAGE'
              )}
              handleCancel={() => setDiscardPopup(false)}
              cancelText={translate('BUTTONS.CANCEL') + ''}
              submitText={translate('BUTTONS.DISCARD') + ''}
              handleSubmit={() => {
                onDisCard();
                setDiscardPopup(false);
              }}
            />
          )}
          {submitPopup && (
            <ConfirmationPopup
              title={
                id ? translate('BUTTONS.UPDATE') : translate('BUTTONS.SUBMIT')
              }
              message={
                translate('TRANSACTION_REGISTRY_PAGE.TITLES.SUBMIT_MESSAGE') +
                ' ' +
                `${
                  id
                    ? translate('BUTTONS.UPDATE').toLowerCase()
                    : translate('BUTTONS.SUBMIT').toLowerCase()
                }?`
              }
              handleCancel={() => setSubmitPopup(false)}
              cancelText={translate('BUTTONS.CANCEL') + ''}
              submitText={
                (id
                  ? translate('BUTTONS.UPDATE')
                  : translate('BUTTONS.SUBMIT')) + ''
              }
              handleSubmit={(e: any) => {
                onSubmit(e);
                setSubmitPopup(false);
              }}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default TransactionRegistry;
