import { Dropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { store } from '../../../../app/store';
import { authLogout } from '../../../../app/store/slices/auth.slice';
import { RootState } from '../../../../app/types/store';
import { useTranslation } from 'react-i18next';
import { userType } from '../../../../constants/user.constant';
import {
  routesConstant,
  imagePath,
} from '../../../../constants/comman.constant';
import {
  certificateType,
  UserProfileStatus,
} from '../../../../shared/remake-green-utils';

export const UserLogs = () => {
  const { t: translate } = useTranslation();
  const state: RootState = store.getState();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isRemakePage = location.pathname === routesConstant.createRemake;
  const logout = () => {
    dispatch(authLogout());
    window.location.href = routesConstant.default;
  };
  return (
    <div
      className={`user-logs`}
      style={{ display: `${!state.auth.isLoggedIn ? 'none' : 'block'}` }}
    >
      <div className='user-login-details'>
        <Link to='#' className='notification'>
          <i className='fa-regular fa-bell'></i>
        </Link>
        <Dropdown>
          <Dropdown.Toggle variant='success'>
            <img
              src={`${process.env.PUBLIC_URL}${imagePath.profileImgPath}`}
              alt={''}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              className='profile'
              disabled={state.auth?.user?.status !== UserProfileStatus.APPROVED}
              as={Link}
              to={routesConstant.remakeUserProfile}
            >
              {/* PCR-204 | User Profile Front End */}
              {/* <Dropdown.Item className="profile" as={Link} to={routesConstant.remakeUserProfile}> */}
              <div className='user'>
                <div className='left'>
                  <img
                    src={`${process.env.PUBLIC_URL}${imagePath.profileImgPath}`}
                    alt={''}
                  />
                </div>
                <div className='right'>
                  <h4>{translate('HOMEPAGE.TITLES.USERNAME')}</h4>
                  <p>{state.auth.user?.userName}</p>
                </div>
              </div>
            </Dropdown.Item>
            {userType.ADMIN == state.auth?.user?.userTypeId && (
              <>
                <Dropdown.Item as={Link} to={routesConstant.userManagement}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/user-management.svg`}
                    alt='user-management'
                  />
                  {translate('HOMEPAGE.TITLES.USER_MANAGEMENT')}
                </Dropdown.Item>
                <Dropdown.Item
                  className='disable'
                  as={Link}
                  to={routesConstant.comingSoon}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/invite-user-icon.svg`}
                    alt='invite user'
                  />
                  {translate('HOMEPAGE.TITLES.INVITE_USER')}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={routesConstant.userManagement}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/user-management.svg`}
                    alt='user-management'
                  />
                  Value-Chain-Partner Management
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={routesConstant.plasticCreditRequest}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/pc-request-management.svg`}
                    alt='plastic credit request management'
                  />

                  {translate('HOMEPAGE.TITLES.PC_REQUEST_MANAGEMENT')}
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={routesConstant.generateCertificate}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/certificate-icon.svg`}
                    alt='invite user'
                  />

                  {translate('HOMEPAGE.TITLES.GENERATE_CERTIFICATE')}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={routesConstant.transactionList}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/transaction-registry.svg`}
                    alt='transaction-registry'
                  />

                  {translate('HOMEPAGE.TITLES.TRANSACTION_REGISTRY')}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={routesConstant.socialWelfare}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/reclaimer_welfare.svg`}
                    alt='social-welfare'
                  />

                  {translate('HOMEPAGE.TITLES.RECLAIMER_WELFARE')}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={routesConstant.CSRActivity}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/ReMake_CSR.svg`}
                    alt='social-welfare'
                  />

                  {translate('HOMEPAGE.TITLES.CSR_ACTIVITY')}
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={routesConstant.domainManagement}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/domain_managment.svg`}
                    alt='social-welfare'
                  />

                  {translate('HOMEPAGE.TITLES.DOMAIN_MANAGEMENT')}
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={routesConstant.footprintManagement}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/foot_print.svg`}
                    alt='social-welfare'
                  />

                  {translate('HOMEPAGE.TITLES.FOOTPRINT_MANAGEMENT')}
                </Dropdown.Item>
              </>
            )}

          {userType.VALUE_CHAIN_PARTNER == state.auth?.user?.userTypeId &&  state.auth?.user?.status === UserProfileStatus.APPROVED && (
              <>
              <Dropdown.Item as={Link} to={routesConstant.transactionList}>
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/transaction-registry.svg`}
                    alt='transaction-registry'
                  />

                  {translate('HOMEPAGE.TITLES.TRANSACTION_REGISTRY')}
                </Dropdown.Item>
               </>
            )}
            {(userType.REMAKE == state.auth?.user?.userTypeId ||
              userType.COMPANY == state.auth?.user?.userTypeId ||
              userType.INDIVIDUAL == state.auth?.user?.userTypeId) &&
              !isRemakePage && (
                <>
                  <Dropdown.Item as={Link} to={'/'}>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/dashboard.svg`}
                      alt='Dashboard'
                    />
                    {translate('HOMEPAGE.TITLES.DASHBOARD')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={
                      routesConstant.remakeCreditRequest +
                      window.btoa(certificateType.COLLECTION.toString())
                    }
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/credit-request-icon.svg`}
                      alt='credit request'
                    />

                    {translate('HOMEPAGE.TITLES.GENERATE_CREDIT_REQUEST')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={routesConstant.plasticCreditManagementList}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/pc-request-management.svg`}
                      alt='plastic credit management'
                    />
                    {translate('HOMEPAGE.TITLES.PC_MANAGEMENT')}
                  </Dropdown.Item>
                </>
              )}
            <Dropdown.Item as={Link} to='#' onClick={logout}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/logout-icon.svg`}
                alt='logout-icon'
              />

              {translate('HOMEPAGE.TITLES.LOGOUT')}
            </Dropdown.Item>

            {/* <Dropdown.Item onClick={logout}>
                            <Link to={"#"}>Logout</Link>
                        </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
